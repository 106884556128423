import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";

const base_url = process.env.REACT_APP_BASE_URL_DEVELOPMENT;

const CustomerLogin = () => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
      password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .required("Required"),
    }),
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        const response = await fetch(`${base_url}/customer/login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        });

        const data = await response.json();

        console.log(data);

        if (response.ok) {
          console.log(data.data.token);
          localStorage.setItem("token", data.data.token);
          localStorage.setItem("userName", data.data.customer.ownerName);
          localStorage.setItem("userType", "customer");

          navigate("/");
        } else {
          setErrors({
            api: data.message || "An error occurred. Please try again.",
          });
        }
      } catch (error) {
        setErrors({ api: "An error occurred. Please try again." });
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <div className="container max-w-md mx-auto xl:max-w-3xl h-full flex bg-white rounded-lg shadow overflow-hidden content-center mt-40">
      <div className="relative hidden xl:block xl:w-1/2 h-full">
        <img
          className="w-full object-fill"
          src="https://images.unsplash.com/photo-1541233349642-6e425fe6190e"
          alt="Background"
        />
      </div>
      <div className="w-full xl:w-1/2 p-8">
        <form onSubmit={formik.handleSubmit}>
          <h1 className="text-2xl font-bold">
            Sign in to your customer account
          </h1>
          <div className="mb-4 mt-6">
            <label
              className="block text-gray-700 text-sm font-semibold mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              className="text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline h-10"
              id="email"
              type="email"
              placeholder="Your email address"
              {...formik.getFieldProps("email")}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="text-red-500 text-sm">{formik.errors.email}</div>
            ) : null}
          </div>
          <div className="mb-6 mt-6">
            <label
              className="block text-gray-700 text-sm font-semibold mb-2"
              htmlFor="password"
            >
              Password
            </label>
            <input
              className="text-sm bg-gray-200 appearance-none rounded w-full py-2 px-3 text-gray-700 mb-1 leading-tight focus:outline-none focus:shadow-outline h-10"
              id="password"
              type="password"
              placeholder="Your password"
              {...formik.getFieldProps("password")}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="text-red-500 text-sm">
                {formik.errors.password}
              </div>
            ) : null}
            {/* <a
              className="inline-block align-baseline text-sm text-gray-600 hover:text-gray-800"
              href="#"
            >
              Forgot Password?
            </a> */}
          </div>
          {formik.errors.api && (
            <div className="text-red-500 text-sm">{formik.errors.api}</div>
          )}
          <div className="flex w-full mt-8">
            <button
              className="w-full bg-gray-800 hover:bg-gray-900 text-white text-sm py-2 px-4 font-semibold rounded focus:outline-none focus:shadow-outline h-10"
              type="submit"
              disabled={formik.isSubmitting}
            >
              {formik.isSubmitting ? "Signing in..." : "Sign in"}
            </button>
          </div>
          <div className="flex w-full mt-8 justify-center">
            <p className="text-sm text-gray-600">
              Student{" "}
              <Link
                to="/student-login"
                className="text-blue-500 hover:text-blue-700"
              >
                Sign in.
              </Link>
            </p>
            <p className="text-sm text-gray-600">
              Teacher{" "}
              <Link
                to="/teacher-login"
                className="text-blue-500 hover:text-blue-700"
              >
                Sign in.
              </Link>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CustomerLogin;
