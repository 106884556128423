import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const base_url = process.env.REACT_APP_BASE_URL_DEVELOPMENT;

const TeacherDetails = () => {
  const { id } = useParams();
  const [teacher, setTeacher] = useState(null);
  const [updatedTeacher, setUpdatedTeacher] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTeacherDetails = async () => {
      const token = localStorage.getItem("token");

      try {
        const response = await fetch(`${base_url}/teacher/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 401) {
          toast.error("Session expired, please login again.");
          navigate("/customer-login"); // Redirect to login page
          return;
        }

        if (!response.ok) {
          throw new Error("Failed to fetch teacher details.");
        }

        const data = await response.json();
        setTeacher(data.data);
        setUpdatedTeacher(data.data); // Set initial values
        setLoading(false);
      } catch (error) {
        console.error("Error fetching teacher details:", error);
        toast.error("Failed to fetch teacher details.");
        setLoading(false);
      }
    };

    fetchTeacherDetails();
  }, [id, navigate]);

  const handleSave = async () => {
    const token = localStorage.getItem("token");

    const updatedFields = Object.keys(updatedTeacher).reduce((acc, key) => {
      if (updatedTeacher[key] !== teacher[key]) {
        acc[key] = updatedTeacher[key];
      }
      return acc;
    }, {});

    if (Object.keys(updatedFields).length === 0) {
      toast.info("No changes made.");
      setIsEditMode(false);
      return;
    }

    try {
      const response = await fetch(`${base_url}/teacher/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedFields),
      });

      if (!response.ok) {
        throw new Error("Failed to update teacher");
      }

      const data = await response.json();
      setTeacher(data.data);
      setIsEditMode(false);
      toast.success("Teacher updated successfully.");
    } catch (error) {
      console.error("Error updating teacher:", error);
      toast.error("Failed to update teacher.");
    }
  };

  const handleDelete = async () => {
    const token = localStorage.getItem("token");
    if (window.confirm("Are you sure you want to delete this teacher?")) {
      try {
        const response = await fetch(`${base_url}/teacher/${id}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to delete teacher");
        }

        toast.success("Teacher deleted successfully");
        navigate("/teachers"); // Navigate back to teacher list after deletion
      } catch (error) {
        console.error("Error deleting teacher:", error);
        toast.error("Failed to delete teacher.");
      }
    }
  };

  const handleCancel = () => {
    setUpdatedTeacher(teacher); // Revert to original state
    setIsEditMode(false);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!teacher) {
    return <div>No teacher data available.</div>;
  }

  return (
    <div className="flex flex-col items-center h-screen p-4">
      <ToastContainer />
      {!isEditMode && (
        <div className="w-full flex justify-end mb-4">
          <button
            className="bg-gradient-to-r from-gray-400 to-gray-700 hover:from-gray-500 hover:to-gray-900 text-white font-semibold py-2 px-6 rounded-full shadow-md hover:shadow-lg transition-all duration-300 ease-in-out"
            onClick={() => navigate("/teachers")}
          >
            Back to Teachers
          </button>
        </div>
      )}

      <div className="bg-white shadow-md rounded-lg p-6 w-[80%]   mb-6">
        <h1 className="text-2xl font-bold mb-6 text-center text-blue-600">
          Teacher Details
        </h1>

        {/* Teacher Details Grid */}
        <div className="grid grid-cols-2 gap-x-8 gap-y-6">
          {/* First Name */}
          <div className="flex items-center">
            <strong className="whitespace-nowrap">First Name:</strong>
            {isEditMode ? (
              <input
                type="text"
                className="ml-2 border-b-2 border-blue-500 focus:outline-none w-full"
                value={updatedTeacher.firstName}
                onChange={(e) =>
                  setUpdatedTeacher({
                    ...updatedTeacher,
                    firstName: e.target.value,
                  })
                }
              />
            ) : (
              <span className="ml-2">{teacher.firstName}</span>
            )}
          </div>

          {/* Last Name */}
          <div className="flex items-center">
            <strong className="whitespace-nowrap">Last Name:</strong>
            {isEditMode ? (
              <input
                type="text"
                className="ml-2 border-b-2 border-blue-500 focus:outline-none w-full"
                value={updatedTeacher.lastName}
                onChange={(e) =>
                  setUpdatedTeacher({
                    ...updatedTeacher,
                    lastName: e.target.value,
                  })
                }
              />
            ) : (
              <span className="ml-2">{teacher.lastName}</span>
            )}
          </div>

          {/* Email */}
          <div className="flex items-center">
            <strong className="whitespace-nowrap">Email:</strong>
            {isEditMode ? (
              <input
                type="email"
                className="ml-2 border-b-2 border-blue-500 focus:outline-none w-full"
                value={updatedTeacher.email}
                onChange={(e) =>
                  setUpdatedTeacher({
                    ...updatedTeacher,
                    email: e.target.value,
                  })
                }
              />
            ) : (
              <span className="ml-2">{teacher.email}</span>
            )}
          </div>

          {/* Phone */}
          <div className="flex items-center">
            <strong className="whitespace-nowrap">Phone Number:</strong>
            {isEditMode ? (
              <input
                type="text"
                className="ml-2 border-b-2 border-blue-500 focus:outline-none w-full"
                value={updatedTeacher.phone}
                onChange={(e) =>
                  setUpdatedTeacher({
                    ...updatedTeacher,
                    phone: e.target.value,
                  })
                }
              />
            ) : (
              <span className="ml-2">{teacher.phone}</span>
            )}
          </div>

          {/* Subjects */}
          <div className="flex items-center">
            <strong className="whitespace-nowrap">Subjects:</strong>
            {isEditMode ? (
              <input
                type="text"
                className="ml-2 border-b-2 border-blue-500 focus:outline-none w-full"
                value={updatedTeacher.subjects.join(", ")}
                onChange={(e) =>
                  setUpdatedTeacher({
                    ...updatedTeacher,
                    subjects: e.target.value
                      .split(",")
                      .map((subject) => subject.trim()),
                  })
                }
              />
            ) : (
              <span className="ml-2">{teacher.subjects.join(", ")}</span>
            )}
          </div>

          {/* Classes */}
          <div className="flex items-center">
            <strong className="whitespace-nowrap">Classes:</strong>
            {isEditMode ? (
              <input
                type="text"
                className="ml-2 border-b-2 border-blue-500 focus:outline-none w-full"
                value={updatedTeacher.classes.join(", ")}
                onChange={(e) =>
                  setUpdatedTeacher({
                    ...updatedTeacher,
                    classes: e.target.value
                      .split(",")
                      .map((classItem) => classItem.trim()),
                  })
                }
              />
            ) : (
              <span className="ml-2">{teacher.classes.join(", ")}</span>
            )}
          </div>

          {/* Address - Street */}
          <div className="flex items-center">
            <strong className="whitespace-nowrap">Street:</strong>
            {isEditMode ? (
              <input
                type="text"
                className="ml-2 border-b-2 border-blue-500 focus:outline-none w-full"
                value={updatedTeacher.address.street}
                onChange={(e) =>
                  setUpdatedTeacher({
                    ...updatedTeacher,
                    address: {
                      ...updatedTeacher.address,
                      street: e.target.value,
                    },
                  })
                }
              />
            ) : (
              <span className="ml-2">{teacher.address.street}</span>
            )}
          </div>

          {/* Address - City */}
          <div className="flex items-center">
            <strong className="whitespace-nowrap">City:</strong>
            {isEditMode ? (
              <input
                type="text"
                className="ml-2 border-b-2 border-blue-500 focus:outline-none w-full"
                value={updatedTeacher.address.city}
                onChange={(e) =>
                  setUpdatedTeacher({
                    ...updatedTeacher,
                    address: {
                      ...updatedTeacher.address,
                      city: e.target.value,
                    },
                  })
                }
              />
            ) : (
              <span className="ml-2">{teacher.address.city}</span>
            )}
          </div>

          {/* Address - State */}
          <div className="flex items-center">
            <strong className="whitespace-nowrap">State:</strong>
            {isEditMode ? (
              <input
                type="text"
                className="ml-2 border-b-2 border-blue-500 focus:outline-none w-full"
                value={updatedTeacher.address.state}
                onChange={(e) =>
                  setUpdatedTeacher({
                    ...updatedTeacher,
                    address: {
                      ...updatedTeacher.address,
                      state: e.target.value,
                    },
                  })
                }
              />
            ) : (
              <span className="ml-2">{teacher.address.state}</span>
            )}
          </div>

          {/* Address - Postal Code */}
          <div className="flex items-center">
            <strong className="whitespace-nowrap">Postal Code:</strong>
            {isEditMode ? (
              <input
                type="text"
                className="ml-2 border-b-2 border-blue-500 focus:outline-none w-full"
                value={updatedTeacher.address.postalCode}
                onChange={(e) =>
                  setUpdatedTeacher({
                    ...updatedTeacher,
                    address: {
                      ...updatedTeacher.address,
                      postalCode: e.target.value,
                    },
                  })
                }
              />
            ) : (
              <span className="ml-2">{teacher.address.postalCode}</span>
            )}
          </div>

          {/* Salary */}
          <div className="flex items-center">
            <strong className="whitespace-nowrap">Salary:</strong>
            {isEditMode ? (
              <input
                type="number"
                className="ml-2 border-b-2 border-blue-500 focus:outline-none w-full"
                value={updatedTeacher.salary.amount}
                onChange={(e) =>
                  setUpdatedTeacher({
                    ...updatedTeacher,
                    salary: {
                      ...updatedTeacher.salary,
                      amount: e.target.value,
                    },
                  })
                }
              />
            ) : (
              <span className="ml-2">
                {teacher.salary.amount} {teacher.salary.currency}
              </span>
            )}
          </div>

          <div className="flex items-center">
            <strong className="whitespace-nowrap">Hire Date:</strong>
            <span className="ml-2">
              {new Date(teacher.createdAt).toLocaleDateString()}
            </span>
          </div>
        </div>

        <div className="flex justify-end mt-6 space-x-2">
          {isEditMode ? (
            <>
              <button
                className="bg-gradient-to-r from-blue-400 to-blue-600 text-white px-4 py-2 rounded hover:from-blue-500 hover:to-blue-700"
                onClick={handleSave}
              >
                Save
              </button>
              <button
                className="bg-gradient-to-r from-gray-400 to-gray-600 text-white px-4 py-2 rounded hover:from-gray-500 hover:to-gray-700"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </>
          ) : (
            <>
              <button
                className="bg-gradient-to-r from-gray-400 to-gray-700 hover:from-gray-500 hover:to-gray-900 text-white font-semibold py-2 px-6 rounded-md shadow-md hover:shadow-lg transition-all duration-300 ease-in-out"
                onClick={() => setIsEditMode(true)}
              >
                Edit Teacher
              </button>
              <button
                className="bg-gradient-to-r from-red-400 to-red-600 text-white px-4 py-2 rounded hover:from-red-500 hover:to-red-700"
                onClick={handleDelete}
              >
                Delete Teacher
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TeacherDetails;
