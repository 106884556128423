import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";
import AddFeeModal from "../modal/AddFeeModal";
import FeeDetailsModal from "../modal/FeeDetailsModal";
import { toast } from "react-toastify";

const base_url = process.env.REACT_APP_BASE_URL_DEVELOPMENT;

const FeeTable = ({ feeDetails, studentId }) => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [currentFeeDetails, setCurrentFeeDetails] = useState(feeDetails || []);
  const [selectedFeeId, setSelectedFeeId] = useState(null);

  console.log("Current Fee details:", currentFeeDetails);

  // Handle adding a new fee
  const handleAddFee = async (newFee) => {
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(`${base_url}/teacher/createNotification`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          ...newFee,
          studentId, // Attach the student ID dynamically
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error("Failed to add fee.");
      }

      // Add the new fee to the local state
      setCurrentFeeDetails((prevDetails) =>
        Array.isArray(prevDetails) ? [...prevDetails, data] : [data]
      );

      // Success message
      toast.success("Fee added successfully!");
    } catch (error) {
      toast.error("Failed to add fee.");
    } finally {
      setIsAddModalOpen(false); // Close modal after submission
    }
  };

  // Handle row click to open FeeDetailsModal
  const handleRowClick = (fee) => {
    setSelectedFeeId(fee._id);
    console.log(fee);
    setIsDetailsModalOpen(true); // Open the details modal
  };

  // Handle fee deletion
  const handleDeleteFee = async (feeId) => {
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(`${base_url}/feeCollections/${feeId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to delete fee.");
      }

      // Remove the deleted fee from the local state
      setCurrentFeeDetails((prevDetails) =>
        prevDetails.filter((fee) => fee._id !== feeId)
      );

      // Success message
      toast.success("Fee deleted successfully!");
    } catch (error) {
      toast.error("Failed to delete fee.");
    } finally {
      setIsDetailsModalOpen(false); // Close modal after deletion
    }
  };

  const handleUpdateFee = (updatedFee) => {
    // Update the fee in the local state by mapping through and updating the specific fee
    setCurrentFeeDetails((prevDetails) =>
      prevDetails.map((fee) => (fee._id === updatedFee._id ? updatedFee : fee))
    );
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-6xl mb-4">
      <div className="flex justify-between">
        <h2 className="text-2xl font-bold mb-6 text-center text-gray-700">
          All Notifications
        </h2>
        <button
          className="bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-semibold py-2 px-6 mb-2 rounded-full shadow-md hover:shadow-lg transition-all duration-300 ease-in-out flex items-center justify-center"
          onClick={() => setIsAddModalOpen(true)}
        >
          <FaPlus className="inline-block mr-2 align-middle" />
          Send Notification
        </button>
      </div>

      <table className="min-w-full bg-white border">
        <thead>
          <tr>
            <th className="py-2 px-4 border">Receipt Number</th>
            <th className="py-2 px-4 border">Amount</th>
            <th className="py-2 px-4 border">Payment Method</th>
            <th className="py-2 px-4 border">Payment Status</th>
            <th className="py-2 px-4 border">Payment For</th>
            <th className="py-2 px-4 border">Late Fee</th>
            <th className="py-2 px-4 border">Date Paid</th>
            <th className="py-2 px-4 border">Remarks</th>
          </tr>
        </thead>
        <tbody>
          {currentFeeDetails && currentFeeDetails.length > 0 ? (
            currentFeeDetails.map((fee, index) => (
              <tr
                key={index}
                onClick={() => handleRowClick(fee)} // Call handleRowClick on click
                className="cursor-pointer hover:bg-gray-100"
              >
                <td className="py-2 px-4 border">{fee.receiptNumber}</td>
                <td className="py-2 px-4 border">${fee.amount}</td>
                <td className="py-2 px-4 border">{fee.paymentMethod}</td>
                <td className="py-2 px-4 border">{fee.paymentStatus}</td>
                <td className="py-2 px-4 border">{fee.paymentFor}</td>
                <td className="py-2 px-4 border">${fee.lateFee}</td>
                <td className="py-2 px-4 border">
                  {new Date(fee.datePaid).toLocaleDateString()}
                </td>
                <td className="py-2 px-4 border">{fee.remarks}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8" className="text-center py-4">
                No fee details available.
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Add Fee Modal */}
      <AddFeeModal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onSubmit={handleAddFee} // Pass handleAddFee here
      />

      {/* Fee Details Modal */}
      {selectedFeeId && (
        <FeeDetailsModal
          isOpen={isDetailsModalOpen}
          onClose={() => setIsDetailsModalOpen(false)}
          Id={selectedFeeId}
          onDelete={handleDeleteFee}
          onUpdate={(updatedFee) => handleUpdateFee(updatedFee)}
        />
      )}
    </div>
  );
};

export default FeeTable;
