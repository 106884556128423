import React, { useState, useEffect } from "react";
import { IoBagHandle } from "react-icons/io5";
import { FaExchangeAlt, FaMoneyBill, FaUserPlus } from "react-icons/fa";
import { AddStudentModal } from "../modal/AddStudentModal";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const base_url = process.env.REACT_APP_BASE_URL_DEVELOPMENT;

export const Student = () => {
  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedClass, setSelectedClass] = useState("All");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");

    fetch(`${base_url}/student`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          navigate("/customer-login");
          return;
        }
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (data && data.data) {
          setStudents(data.data);
          setFilteredStudents(data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching student data:", error);
      });
  }, [navigate]);

  useEffect(() => {
    let filtered = students;

    if (searchQuery) {
      filtered = filtered.filter((student) =>
        `${student.firstName} ${student.lastName}`
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      );
    }

    if (selectedClass !== "All") {
      filtered = filtered.filter((student) => student.class === selectedClass);
    }

    setFilteredStudents(filtered);
  }, [searchQuery, selectedClass, students]);

  const handleAddStudent = (newStudent) => {
    const token = localStorage.getItem("token");

    fetch(`${base_url}/student`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(newStudent),
    })
      .then((response) => {
        if (response.status === 401) {
          navigate("/customer-login");
          return;
        }
        return response.json();
      })
      .then((data) => {
        if (data && data.data && data.data.user) {
          setStudents((prev) => [...prev, data.data.user]);
          setIsModalOpen(false);
          toast.success("Student added successfully!");
        } else {
          toast.error("Failed to add student. Invalid response.");
        }
      })
      .catch((error) => {
        console.error("Error adding student:", error);
        toast.error("Failed to add student.");
      });
  };

  const handleRowClick = (studentId) => {
    navigate(`/student/${studentId}`);
  };

  return (
    <div className="flex flex-col gap-4 w-full">
      <ToastContainer />
      {/* Statistics Boxes */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        <BoxWrapper>
          <div className="rounded-full h-12 w-12 flex items-center justify-center bg-sky-500">
            <IoBagHandle className="text-2xl text-white" />
          </div>
          <div className="pl-4">
            <span className="text-sm text-gray-500 font-light">New sales</span>
            <div className="flex items-center">
              <strong className="text-xl text-gray-700 font-semibold">
                1,345
              </strong>
            </div>
          </div>
        </BoxWrapper>
        <BoxWrapper>
          <div className="rounded-full h-12 w-12 flex items-center justify-center bg-orange-500">
            <FaUserPlus className="text-2xl text-white" />
          </div>
          <div className="pl-4">
            <span className="text-sm text-gray-500 font-light">New leads</span>
            <div className="flex items-center">
              <strong className="text-xl text-gray-700 font-semibold">
                2,2345
              </strong>
            </div>
          </div>
        </BoxWrapper>
        <BoxWrapper>
          <div className="rounded-full h-12 w-12 flex items-center justify-center bg-yellow-500">
            <FaMoneyBill className="text-2xl text-white" />
          </div>
          <div className="pl-4">
            <span className="text-sm text-gray-500 font-light">
              Income per leads
            </span>
            <div className="flex items-center">
              <strong className="text-xl text-gray-700 font-semibold">
                $1,345
              </strong>
            </div>
          </div>
        </BoxWrapper>
        <BoxWrapper>
          <div className="rounded-full h-12 w-12 flex items-center justify-center bg-blue-500">
            <FaExchangeAlt className="text-2xl text-white" />
          </div>
          <div className="pl-4">
            <span className="text-sm text-gray-500 font-light">
              Conversion rate
            </span>
            <div className="flex items-center">
              <strong className="text-xl text-gray-700 font-semibold">
                5.10%
              </strong>
            </div>
          </div>
        </BoxWrapper>
      </div>

      <div
        className="bg-white px-4 pt-3 pb-4 rounded-sm border border-gray-200 flex-1"
        style={{ maxHeight: "700px" }}
      >
        <div className="flex justify-between items-center flex-wrap gap-2 mb-4">
          <div className="flex items-center gap-2">
            <h2 className="text-2xl font-bold text-gray-700">Students</h2>
            <select
              className="border border-gray-300 rounded p-2 bg-gray-100 hover:bg-gray-200 cursor-pointer"
              value={selectedClass}
              onChange={(e) => setSelectedClass(e.target.value)}
            >
              <option value="All">All Classes</option>
              <option value="1">Class 1</option>
              <option value="2">Class 2</option>
              <option value="3">Class 3</option>
              <option value="4">Class 4</option>
              <option value="5">Class 5</option>
              <option value="6">Class 6</option>
              <option value="7">Class 7</option>
              <option value="8">Class 8</option>
              <option value="9">Class 9</option>
              <option value="10">Class 10th</option>
              <option value="11">Class 11th</option>
              <option value="12">Class 12th</option>
            </select>
          </div>
          <div className="flex items-center gap-2">
            <input
              type="text"
              placeholder="Search by name..."
              className="border border-gray-300 rounded p-2 bg-gray-100 focus:bg-white focus:border-gray-500"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <button
              className="bg-gradient-to-r from-gray-400 to-gray-700 hover:from-gray-500 hover:to-gray-900 text-white font-semibold py-2 px-6 rounded-full shadow-md hover:shadow-lg transition-all duration-300 ease-in-out"
              onClick={() => setIsModalOpen(true)}
            >
              Add Student
            </button>
          </div>
        </div>

        {/* Table Section */}
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-300">
            <thead>
              <tr className="bg-gray-100 text-left text-gray-600 uppercase">
                <th className="py-2 px-4 border">Name</th>
                <th className="py-2 px-4 border">Enrollment Date</th>
                <th className="py-2 px-4 border">Address</th>
                <th className="py-2 px-4 border">Class</th>
                <th className="py-2 px-4 border">Contact No.</th>
              </tr>
            </thead>
            <tbody>
              {filteredStudents && filteredStudents.length > 0 ? (
                filteredStudents.map((student) => (
                  <tr
                    key={student?._id}
                    onClick={() => handleRowClick(student?._id)}
                    className="cursor-pointer hover:bg-gray-100"
                  >
                    <td className="py-2 px-4 border">
                      {student?.firstName} {student?.lastName}
                    </td>
                    <td className="py-2 px-4 border">
                      {new Date(student?.createdAt).toLocaleDateString()}
                    </td>
                    <td className="py-2 px-4 border">
                      {student?.personalDetails?.address}
                    </td>
                    <td className="py-2 px-4 border">{student?.class}</td>
                    <td className="py-2 px-4 border">
                      {student?.personalDetails?.studentPhoneNumber}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center py-4">
                    No students found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <AddStudentModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleAddStudent}
        title="Add New Student"
      />
    </div>
  );
};

function BoxWrapper({ children }) {
  return (
    <div className="bg-white rounded-sm p-4 flex-1 border border-gray-200 flex items-center">
      {children}
    </div>
  );
}
