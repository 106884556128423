import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";

const FeeDetailsModal = ({ isOpen, onClose, Id, onDelete, onUpdate }) => {
  const [feeDetails, setFeeDetails] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    if (isOpen && Id) {
      setIsEditing(false); // Reset to non-edit mode

      const token = localStorage.getItem("token");
      const base_url = process.env.REACT_APP_BASE_URL_DEVELOPMENT;

      fetch(`${base_url}/feeCollections/getbyid/${Id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          console.log(response);
          if (response.status === 401) {
            toast.error("Session expired. Please log in again.");
            return;
          }
          if (!response.ok) {
            throw new Error("Failed to fetch fee details");
          }
          return response.json();
        })

        .then((data) => {
          console.log(data);
          setFeeDetails(data.data);
          setFormData(data.data); // Set form data for editing
        })
        .catch((error) => {
          console.error("Error fetching fee details:", error);
          toast.error("Failed to load fee details.");
        });
    }
  }, [isOpen, Id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSaveChanges = () => {
    const token = localStorage.getItem("token");
    const base_url = process.env.REACT_APP_BASE_URL_DEVELOPMENT;

    const cleanedFormData = { ...formData };
    delete cleanedFormData._id;
    delete cleanedFormData.transactionId;
    delete cleanedFormData.createdAt;
    delete cleanedFormData.updatedAt;
    delete cleanedFormData.__v;

    fetch(`${base_url}/feeCollections/${Id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(cleanedFormData),
    })
      .then((response) => response.json()) // Parse the JSON directly
      .then((data) => {
        // Check the status field in the JSON response
        if (data.status === 200 || data.success === true) {
          setIsEditing(false);
          setFeeDetails(data.data); // Update modal fee details immediately
          onUpdate(data.data); // Call the onUpdate callback to update the table
          toast.success(data.message || "Fee details updated successfully");
        } else {
          throw new Error(data.message || "Failed to save changes");
        }
      })
      .catch((error) => {
        console.error("Error updating fee details:", error);
        toast.error(error.message || "Failed to update fee details.");
      });
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  if (!isOpen || !feeDetails) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div
        className="bg-white p-6 rounded-lg shadow-lg relative"
        style={{ width: "50rem" }}
      >
        {/* Close Button */}
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
          onClick={onClose}
        >
          <FaTimes className="text-xl" />
        </button>

        {/* Title */}
        <h2 className="text-2xl font-bold text-center text-blue-600 mb-6">
          Fee Details
        </h2>

        {/* Fee Details Grid */}
        <div className="grid grid-cols-2 gap-x-8  gap-y-6">
          {/** Ensure labels and inputs are on the same line and do not break */}
          <div className="flex items-center">
            <strong className="whitespace-nowrap">Amount:</strong>
            {isEditing ? (
              <input
                type="text"
                name="amount"
                value={formData.amount}
                onChange={handleInputChange}
                className="ml-2 border-b-2 border-blue-500 focus:outline-none w-full"
              />
            ) : (
              <span className="ml-2">{feeDetails.amount}</span>
            )}
          </div>
          <div className="flex items-center">
            <strong className="whitespace-nowrap">Payment Method:</strong>
            {isEditing ? (
              <input
                type="text"
                name="paymentMethod"
                value={formData.paymentMethod}
                onChange={handleInputChange}
                className="ml-2 border-b-2 border-blue-500 focus:outline-none w-full"
              />
            ) : (
              <span className="ml-2">{feeDetails.paymentMethod}</span>
            )}
          </div>
          <div className="flex items-center">
            <strong className="whitespace-nowrap">Remarks:</strong>
            {isEditing ? (
              <input
                type="text"
                name="remarks"
                value={formData.remarks}
                onChange={handleInputChange}
                className="ml-2 border-b-2 border-blue-500 focus:outline-none w-full"
              />
            ) : (
              <span className="ml-2">{feeDetails.remarks}</span>
            )}
          </div>
          <div className="flex items-center">
            <strong className="whitespace-nowrap">Payment Status:</strong>
            {isEditing ? (
              <input
                type="text"
                name="paymentStatus"
                value={formData.paymentStatus}
                onChange={handleInputChange}
                className="ml-2 border-b-2 border-blue-500 focus:outline-none w-full"
              />
            ) : (
              <span className="ml-2">{feeDetails.paymentStatus}</span>
            )}
          </div>
          <div className="flex items-center">
            <strong className="whitespace-nowrap">Due Date:</strong>
            {isEditing ? (
              <input
                type="text"
                name="dueDate"
                value={formatDate(formData.dueDate)}
                onChange={handleInputChange}
                className="ml-2 border-b-2 border-blue-500 focus:outline-none w-full"
              />
            ) : (
              <span className="ml-2">{formatDate(feeDetails.dueDate)}</span>
            )}
          </div>
          <div className="flex items-center">
            <strong className="whitespace-nowrap">Late Fee:</strong>
            {isEditing ? (
              <input
                type="text"
                name="lateFee"
                value={formData.lateFee}
                onChange={handleInputChange}
                className="ml-2 border-b-2 border-blue-500 focus:outline-none w-full"
              />
            ) : (
              <span className="ml-2">{feeDetails.lateFee}</span>
            )}
          </div>
          <div className="flex items-center">
            <strong className="whitespace-nowrap">Discount:</strong>
            {isEditing ? (
              <input
                type="text"
                name="discount"
                value={formData.discount}
                onChange={handleInputChange}
                className="ml-2 border-b-2 border-blue-500 focus:outline-none w-full"
              />
            ) : (
              <span className="ml-2">{feeDetails.discount}</span>
            )}
          </div>
          <div className="flex items-center">
            <strong className="whitespace-nowrap">Receipt Number:</strong>
            {isEditing ? (
              <input
                type="text"
                name="receiptNumber"
                value={formData.receiptNumber}
                onChange={handleInputChange}
                className="ml-2 border-b-2 border-blue-500 focus:outline-none w-full"
              />
            ) : (
              <span className="ml-2">{feeDetails.receiptNumber}</span>
            )}
          </div>
          <div className="flex items-center">
            <strong className="whitespace-nowrap">Payment For:</strong>
            {isEditing ? (
              <input
                type="text"
                name="paymentFor"
                value={formData.paymentFor}
                onChange={handleInputChange}
                className="ml-2 border-b-2 border-blue-500 focus:outline-none w-full"
              />
            ) : (
              <span className="ml-2">{feeDetails.paymentFor}</span>
            )}
          </div>
          <div className="flex items-center">
            <strong className="whitespace-nowrap">Academic Year:</strong>
            {isEditing ? (
              <input
                type="text"
                name="academicYear"
                value={formData.academicYear}
                onChange={handleInputChange}
                className="ml-2 border-b-2 border-blue-500 focus:outline-none w-full"
              />
            ) : (
              <span className="ml-2">{feeDetails.academicYear}</span>
            )}
          </div>
          <div className="flex items-center">
            <strong className="whitespace-nowrap">Date Paid:</strong>
            {isEditing ? (
              <input
                type="text"
                name="datePaid"
                value={formatDate(formData.datePaid)}
                onChange={handleInputChange}
                className="ml-2 border-b-2 border-blue-500 focus:outline-none w-full"
              />
            ) : (
              <span className="ml-2">{formatDate(feeDetails.datePaid)}</span>
            )}
          </div>
        </div>

        {/* Buttons */}
        <div className="flex justify-end mt-6 space-x-2">
          {isEditing ? (
            <button
              className="bg-gradient-to-r from-blue-400 to-blue-600 text-white px-4 py-2 rounded hover:from-blue-500 hover:to-blue-700"
              onClick={handleSaveChanges}
            >
              Save Changes
            </button>
          ) : (
            <>
              <button
                className="bg-gradient-to-r from-green-400 to-green-600 text-white px-4 py-2 rounded hover:from-green-500 hover:to-green-700"
                onClick={() => setIsEditing(true)}
              >
                Edit Fee
              </button>
              <button
                className="bg-gradient-to-r from-red-400 to-red-600 text-white px-4 py-2 rounded hover:from-red-500 hover:to-red-700"
                onClick={() => onDelete(feeDetails._id)}
              >
                Delete Fee
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FeeDetailsModal;
