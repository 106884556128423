// Notifications.js
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);

  const base_url = process.env.REACT_APP_BASE_URL_DEVELOPMENT; // Ensure this is set in your .env file

  // Fetch notifications when the component mounts
  useEffect(() => {
    const fetchNotifications = async () => {
      const token = localStorage.getItem("token");

      try {
        const response = await fetch(`${base_url}/notification`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 401) {
          toast.error("Session expired, please login again.");
          return;
        }

        if (!response.ok) throw new Error("Failed to fetch notifications");

        const data = await response.json();
        setNotifications(data?.data || []);
      } catch (error) {
        console.error("Error fetching notifications:", error);
        toast.error("Failed to fetch notifications.");
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, [base_url]);

  const toggleAccordion = (index) => {
    setNotifications((prevNotifications) =>
      prevNotifications.map((notification, i) =>
        i === index
          ? { ...notification, isOpen: !notification.isOpen }
          : notification
      )
    );
  };

  return (
    <div className="p-6 max-w-6xl mx-auto">
      <ToastContainer />
      <h1 className="text-3xl font-bold text-blue-600 mb-6">Notifications</h1>

      {loading ? (
        <p>Loading notifications...</p>
      ) : notifications.length > 0 ? (
        <div className="space-y-4">
          {notifications.map((notification, index) => (
            <div key={index} className="border rounded-lg">
              <button
                onClick={() => toggleAccordion(index)}
                className="w-full text-left p-4 bg-gray-200 font-semibold text-lg focus:outline-none"
              >
                {notification.title}
              </button>
              {notification.isOpen && (
                <div className="p-4 bg-white border-t text-gray-700">
                  <p>{notification.message}</p>
                  <p className="text-xs text-gray-500 mt-2">
                    <strong>Recipient:</strong> {notification.recipientType}
                  </p>
                  <p className="text-xs text-gray-500">
                    <strong>Expires At:</strong>{" "}
                    {new Date(notification.expiresAt).toLocaleString()}
                  </p>
                  {notification.attachments?.length > 0 && (
                    <div className="mt-2">
                      <strong>Attachments:</strong>
                      <ul className="list-disc pl-4">
                        {notification.attachments.map((attachment, idx) => (
                          <li key={idx}>
                            <a
                              href={attachment.fileUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-500 underline"
                            >
                              {attachment.fileName}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <p className="text-gray-500">No notifications available.</p>
      )}
    </div>
  );
};

export default Notifications;
