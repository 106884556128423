// AddStudentModal.js
import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";

export const AddStudentModal = ({ isOpen, onClose, onSubmit, title }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    class: "",
    modeOfTransport: "School",
    personalDetails: {
      father: "",
      mother: "",
      gardian: "",
      fatherGardianPhoneNumber: "",
      bloodGroup: "",
      studentPhoneNumber: "",
      address: "",
      gender: "",
      pic: "",
    },
  });

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [complaintDetails, setComplaintDetails] = useState(null); // Store the details of the created complaint

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name in formData.personalDetails) {
      setFormData({
        ...formData,
        personalDetails: {
          ...formData.personalDetails,
          [name]: value,
        },
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.firstName) newErrors.firstName = "First name is required";
    if (!formData.lastName) newErrors.lastName = "Last name is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.class) newErrors.class = "Class is required";
    if (!formData.personalDetails.father)
      newErrors.father = "Father's name is required";
    if (!formData.personalDetails.address)
      newErrors.address = "Address is required";

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      onSubmit(formData);
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        class: "",
        modeOfTransport: "School",
        personalDetails: {
          father: "",
          mother: "",
          gardian: "",
          fatherGardianPhoneNumber: "",
          bloodGroup: "",
          studentPhoneNumber: "",
          address: "",
          gender: "",
          pic: "",
        },
      });
      setErrors({});
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-2xl w-full relative">
        <button
          className="absolute top-3 right-3 text-gray-600 hover:text-gray-900"
          onClick={onClose}
        >
          <FaTimes className="text-xl" />
        </button>

        <h2 className="text-2xl font-bold mb-2 text-center text-gray-800">
          {title}
        </h2>

        <hr className="border-t-2 border-gray-800 mb-6" />

        <form>
          <div className="grid grid-cols-3 gap-4">
            <div>
              <label className="text-gray-700 mb-1 block">First Name</label>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                className={`w-full p-2 border rounded ${
                  errors.firstName ? "border-red-500" : "border-gray-300"
                }`}
              />
              {errors.firstName && (
                <p className="text-red-500 text-sm">{errors.firstName}</p>
              )}
            </div>

            <div>
              <label className="text-gray-700 mb-1 block">Last Name</label>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                className={`w-full p-2 border rounded ${
                  errors.lastName ? "border-red-500" : "border-gray-300"
                }`}
              />
              {errors.lastName && (
                <p className="text-red-500 text-sm">{errors.lastName}</p>
              )}
            </div>

            <div>
              <label className="text-gray-700 mb-1 block">Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className={`w-full p-2 border rounded ${
                  errors.email ? "border-red-500" : "border-gray-300"
                }`}
              />
              {errors.email && (
                <p className="text-red-500 text-sm">{errors.email}</p>
              )}
            </div>

            <div>
              <label className="text-gray-700 mb-1 block">Class</label>
              <input
                type="text"
                name="class"
                value={formData.class}
                onChange={handleInputChange}
                className={`w-full p-2 border rounded ${
                  errors.class ? "border-red-500" : "border-gray-300"
                }`}
              />
              {errors.class && (
                <p className="text-red-500 text-sm">{errors.class}</p>
              )}
            </div>

            <div>
              <label className="text-gray-700 mb-1 block">
                Mode of Transport
              </label>
              <select
                name="modeOfTransport"
                value={formData.modeOfTransport}
                onChange={handleInputChange}
                className="w-full p-2 border rounded border-gray-300"
              >
                <option value="School">School</option>
                <option value="Personal">Personal</option>
              </select>
            </div>

            <div>
              <label className="text-gray-700 mb-1 block">Gender</label>
              <select
                name="gender"
                value={formData.personalDetails.gender}
                onChange={handleInputChange}
                className="w-full p-2 border rounded border-gray-300"
              >
                <option value="">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>

            <div>
              <label className="text-gray-700 mb-1 block">Blood Group</label>
              <select
                name="bloodGroup"
                value={formData.personalDetails.bloodGroup}
                onChange={handleInputChange}
                className="w-full p-2 border rounded border-gray-300"
              >
                <option value="">Select Blood Group</option>
                <option value="A+">A+</option>
                <option value="B+">B+</option>
                <option value="AB+">AB+</option>
                <option value="O+">O+</option>
              </select>
            </div>

            {/* Additional personal details fields */}
            <div>
              <label className="text-gray-700 mb-1 block">Father's Name</label>
              <input
                type="text"
                name="father"
                value={formData.personalDetails.father}
                onChange={handleInputChange}
                className="w-full p-2 border rounded border-gray-300"
              />
            </div>

            <div>
              <label className="text-gray-700 mb-1 block">Mother's Name</label>
              <input
                type="text"
                name="mother"
                value={formData.personalDetails.mother}
                onChange={handleInputChange}
                className="w-full p-2 border rounded border-gray-300"
              />
            </div>

            <div>
              <label className="text-gray-700 mb-1 block">
                Guardian's Name
              </label>
              <input
                type="text"
                name="gardian"
                value={formData.personalDetails.gardian}
                onChange={handleInputChange}
                className="w-full p-2 border rounded border-gray-300"
              />
            </div>

            <div>
              <label className="text-gray-700 mb-1 block">
                Guardian's Phone
              </label>
              <input
                type="text"
                name="fatherGardianPhoneNumber"
                value={formData.personalDetails.fatherGardianPhoneNumber}
                onChange={handleInputChange}
                className="w-full p-2 border rounded border-gray-300"
              />
            </div>

            <div>
              <label className="text-gray-700 mb-1 block">Student Phone</label>
              <input
                type="text"
                name="studentPhoneNumber"
                value={formData.personalDetails.studentPhoneNumber}
                onChange={handleInputChange}
                className="w-full p-2 border rounded border-gray-300"
              />
            </div>

            <div>
              <label className="text-gray-700 mb-1 block">Address</label>
              <input
                type="text"
                name="address"
                value={formData.personalDetails.address}
                onChange={handleInputChange}
                className="w-full p-2 border rounded border-gray-300"
              />
            </div>

            <div>
              <label className="text-gray-700 mb-1 block">
                Profile Picture URL
              </label>
              <input
                type="text"
                name="pic"
                value={formData.personalDetails.pic}
                onChange={handleInputChange}
                className="w-full p-2 border rounded border-gray-300"
              />
            </div>
          </div>
        </form>

        <div className="flex justify-end mt-6">
          <button
            className="w-full bg-gradient-to-r from-gray-500 to-gray-900 text-white px-4 py-2 rounded hover:from-gray-700 hover:to-gray-800"
            onClick={handleSubmit}
          >
            Add Complaint
          </button>
        </div>
      </div>
    </div>
  );
};
