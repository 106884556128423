import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FeeTable from "./FeeTable";

const base_url = process.env.REACT_APP_BASE_URL_DEVELOPMENT;

const StudentDetails = () => {
  const { id } = useParams();
  const [student, setStudent] = useState(null);
  const [updatedStudent, setUpdatedStudent] = useState({});
  const [feeDetails, setFeeDetails] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [feeLoading, setFeeLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStudentDetails = async () => {
      const token = localStorage.getItem("token");

      try {
        const response = await fetch(`${base_url}/student/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 401) {
          toast.error("Session expired, please login again.");
          return;
        }

        const data = await response.json();
        setStudent(data.data);
        setUpdatedStudent(data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching student details:", error);
        toast.error("Failed to fetch student details.");
        setLoading(false);
      }
    };

    const fetchFeeDetails = async () => {
      const token = localStorage.getItem("token");

      try {
        const response = await fetch(
          `${base_url}/feeCollections/getbystudentid/${id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 401) {
          toast.error("Session expired, please login again.");
          return;
        }

        const feeData = await response.json();
        setFeeDetails(feeData || []);
        setFeeLoading(false);
      } catch (error) {
        console.error("Error fetching fee details:", error);
        toast.error("Failed to fetch fee details.");
        setFeeLoading(false);
      }
    };

    fetchStudentDetails();
    fetchFeeDetails();
  }, [id]);

  const handleSave = async () => {
    const token = localStorage.getItem("token");

    const updatedFields = Object.keys(updatedStudent).reduce((acc, key) => {
      if (updatedStudent[key] !== student[key]) {
        acc[key] = updatedStudent[key];
      }
      return acc;
    }, {});

    if (Object.keys(updatedFields).length === 0) {
      toast.info("No changes made.");
      setIsEditMode(false);
      return;
    }

    try {
      const response = await fetch(`${base_url}/student/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedFields),
      });

      if (!response.ok) {
        throw new Error("Failed to update student");
      }

      const data = await response.json();
      setStudent(data.data);
      setIsEditMode(false);
      toast.success("Student updated successfully.");
    } catch (error) {
      console.error("Error updating student:", error);
      toast.error("Failed to update student.");
    }
  };

  const handleDelete = async () => {
    const token = localStorage.getItem("token");
    if (window.confirm("Are you sure you want to delete this student?")) {
      try {
        const response = await fetch(`${base_url}/student/${id}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to delete student");
        }

        toast.success("Student deleted successfully");
        navigate("/student");
      } catch (error) {
        console.error("Error deleting student:", error);
        toast.error("Failed to delete student.");
      }
    }
  };

  const handleCancel = () => {
    setUpdatedStudent(student);
    setIsEditMode(false);
  };

  if (loading || feeLoading) {
    return <div>Loading...</div>;
  }

  if (!student) {
    return <div>No student data available.</div>;
  }

  return (
    <div className="flex flex-col items-center h-screen p-4 bg-gray-100">
      <ToastContainer />
      {!isEditMode && (
        <div className="w-full flex justify-end mb-4">
          <button
            className="bg-gradient-to-r from-gray-400 to-gray-700 hover:from-gray-500 hover:to-gray-900 text-white font-semibold py-2 px-6 rounded-full shadow-md hover:shadow-lg transition-all duration-300 ease-in-out"
            onClick={() => navigate("/student")}
          >
            Back to Students
          </button>
        </div>
      )}

      <div
        className="bg-white shadow-lg rounded-lg p-6 w-full max-w-6xl mb-6 flex flex-col md:flex-row items-center md:items-start"
        style={{ width: "100%" }}
      >
        {/* Profile Image */}
        <div className="md:w-1/3 lg:w-1/5 flex justify-center md:justify-center mb-4 md:mb-0">
          <img
            src={
              student.personalDetails?.pic || "https://via.placeholder.com/150"
            }
            alt="Student Profile"
            className="w-36 h-36 rounded-full object-cover border-4 border-blue-500 shadow-lg"
          />
        </div>

        {/* Student Details */}
        <div className="md:w-2/3 lg:w-4/5 flex flex-col items-start md:pl-8">
          <h2 className="text-3xl font-semibold text-blue-600 mb-2">
            {student.firstName} {student.lastName}
          </h2>
          <p className="text-gray-500 mb-4">{student.email}</p>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
            {[
              {
                label: "Phone Number",
                value: student.personalDetails?.studentPhoneNumber,
                key: "studentPhoneNumber",
              },
              {
                label: "Address",
                value: student.personalDetails?.address,
                key: "address",
              },
              {
                label: "Blood Group",
                value: student.personalDetails?.bloodGroup,
                key: "bloodGroup",
              },
              {
                label: "Gender",
                value: student.personalDetails?.gender,
                key: "gender",
              },
              {
                label: "Father's Name",
                value: student.personalDetails?.father,
                key: "father",
              },
              {
                label: "Mother's Name",
                value: student.personalDetails?.mother,
                key: "mother",
              },
              {
                label: "Guardian's Name",
                value: student.personalDetails?.gardian,
                key: "gardian",
              },
              {
                label: "Guardian's Phone",
                value: student.personalDetails?.fatherGardianPhoneNumber,
                key: "fatherGardianPhoneNumber",
              },
            ].map((detail) => (
              <div key={detail.key}>
                <p className="text-gray-700 font-bold">{detail.label}:</p>
                {isEditMode ? (
                  <input
                    type="text"
                    className="ml-2 border-b-2 border-blue-500 focus:outline-none w-full"
                    value={
                      updatedStudent.personalDetails?.[detail.key] ||
                      detail.value ||
                      ""
                    }
                    onChange={(e) =>
                      setUpdatedStudent({
                        ...updatedStudent,
                        personalDetails: {
                          ...updatedStudent.personalDetails,
                          [detail.key]: e.target.value,
                        },
                      })
                    }
                  />
                ) : (
                  <p className="text-gray-900 ml-2">{detail.value}</p>
                )}
              </div>
            ))}
          </div>

          <div className="flex justify-end mt-6 space-x-4 w-full ">
            {isEditMode ? (
              <>
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600"
                  onClick={handleSave}
                >
                  Save
                </button>
                <button
                  className="bg-gray-500 text-white px-4 py-2 rounded shadow hover:bg-gray-600"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </>
            ) : (
              <>
                <button
                  className="bg-gradient-to-r from-gray-400 to-gray-700 hover:from-gray-500 hover:to-gray-900 text-white font-semibold py-2 px-6 rounded-md shadow-md hover:shadow-lg transition-all duration-300 ease-in-out"
                  onClick={() => setIsEditMode(true)}
                >
                  Edit Student
                </button>
                <button
                  className="bg-red-500 text-white px-4 py-2 rounded shadow hover:bg-red-600"
                  onClick={handleDelete}
                >
                  Delete Student
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Fee Table */}
      {!isEditMode && <FeeTable feeDetails={feeDetails.data} studentId={id} />}
    </div>
  );
};

export default StudentDetails;
