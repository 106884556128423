import React, { useState, useEffect } from "react";
import { IoBagHandle } from "react-icons/io5";
import { FaExchangeAlt, FaMoneyBill, FaUserPlus } from "react-icons/fa";
import { AddStudentModal } from "../modal/AddStudentModal";
import { ComplaintDetailsModal } from "../modal/AddComplaintModal"; // Import the modal
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const base_url = process.env.REACT_APP_BASE_URL_DEVELOPMENT;

export const Complaint = () => {
  const [complaints, setComplaints] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedComplaint, setSelectedComplaint] = useState(null); // State to track the selected complaint
  const navigate = useNavigate();

  useEffect(() => {
    // This mock data replaces the API call for now
    const mockData = {
      status: 200,
      data: {
        complaints: [
          {
            _id: "66ed261c13c31e1c68a0b6e6",
            studentId: "66cc2d7d3ec4d3786e78f2df",
            customerId: "66c9cc983ec4d3786e78f2a4",
            subject: "Broken Classroom Chair",
            description:
              "The chair in room 101 is broken and unsafe to sit on. Please replace it as soon as possible.",
            status: "Pending",
            roomNumber: "101",
            createdAt: "2024-09-19T11:34:13.151Z",
            updatedAt: "2024-09-19T11:34:13.151Z",
          },
          {
            _id: "66ed286113c31e1c68a0b6ff",
            studentId: "66cc2d7d3ec4d3786e78f2df",
            customerId: "66c9cc983ec4d3786e78f2a4",
            subject: "Broken Classroom Chair",
            description:
              "The chair in room 101 is broken and unsafe to sit on. Please replace it as soon as possible.",
            status: "Pending",
            roomNumber: "101",
            createdAt: "2024-09-19T11:34:13.151Z",
            updatedAt: "2024-09-19T11:34:13.151Z",
          },
          {
            _id: "66ed287513c31e1c68a0b702",
            studentId: "66cc2d7d3ec4d3786e78f2df",
            customerId: "66c9cc983ec4d3786e78f2a4",
            subject: "Broken Classroom Chair",
            description:
              "The chair in room 101 is broken and unsafe to sit on. Please replace it as soon as possible.",
            status: "Pending",
            roomNumber: "105",
            createdAt: "2024-09-19T11:34:13.151Z",
            updatedAt: "2024-09-19T11:34:13.151Z",
          },
          //... (other complaints)
        ],
      },
      message: "9 complaint(s) found made by the user",
    };

    // Simulate API response using mock data
    setComplaints(mockData.data.complaints);
  }, [navigate]);

  const handleAddComplaint = (newComplaint) => {
    const token = localStorage.getItem("token");

    fetch(`${base_url}/student/complaint`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(newComplaint),
    })
      .then((response) => {
        if (response.status === 401) {
          navigate("/customer-login");
          return;
        }
        return response.json();
      })
      .then((data) => {
        if (data && data.data) {
          setComplaints((prev) => [...prev, data.data]);
          setIsModalOpen(false);
          toast.success("Complaint added successfully!");
        } else {
          toast.error("Failed to add complaint. Invalid response.");
        }
      })
      .catch((error) => {
        console.error("Error adding complaint:", error);
        toast.error("Failed to add complaint.");
      });
  };

  const handleRowClick = (complaint) => {
    setSelectedComplaint(complaint); // Set the selected complaint
  };

  return (
    <div className="flex flex-col gap-4 w-full">
      <ToastContainer />
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        {/* Statistics Boxes */}
      </div>

      <div
        className="bg-white px-4 pt-3 pb-4 rounded-sm border border-gray-200 flex-1"
        style={{ maxHeight: "700px" }}
      >
        <div className="flex justify-between items-center flex-wrap gap-2">
          <strong className="text-gray-700 font-medium">Complaint</strong>
          <button
            className="bg-gradient-to-r from-gray-400 to-gray-700 hover:from-gray-500 hover:to-gray-900 text-white font-semibold py-2 px-6 mb-2 rounded-full shadow-md hover:shadow-lg transition-all duration-300 ease-in-out"
            onClick={() => setIsModalOpen(true)}
          >
            Add Complaint
          </button>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-300">
            <thead>
              <tr className="bg-gray-100 text-left text-gray-600 uppercase">
                <th className="py-2 px-4 border">Subject</th>
                <th className="py-2 px-4 border">Description</th>
                <th className="py-2 px-4 border">Room No.</th>
                <th className="py-2 px-4 border">Status</th>
                <th className="py-2 px-4 border">Date</th>
              </tr>
            </thead>
            <tbody>
              {complaints && complaints.length > 0 ? (
                complaints.map((complaint) => (
                  <tr
                    key={complaint?._id}
                    onClick={() => handleRowClick(complaint)} // Pass the complaint data
                    className="cursor-pointer hover:bg-gray-100"
                  >
                    <td className="py-2 px-4 border">{complaint?.subject}</td>
                    <td className="py-2 px-4 border">
                      {complaint?.description}
                    </td>
                    <td className="py-2 px-4 border">
                      {complaint?.roomNumber}
                    </td>
                    <td className="py-2 px-4 border">{complaint?.status}</td>
                    <td className="py-2 px-4 border">
                      {new Date(complaint?.createdAt).toLocaleDateString()}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center py-4">
                    No complaint found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Complaint Details Modal */}
      {selectedComplaint && (
        <ComplaintDetailsModal
          isOpen={!!selectedComplaint}
          onClose={() => setSelectedComplaint(null)} // Close the modal
          complaint={selectedComplaint} // Pass the selected complaint data
        />
      )}

      <AddStudentModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleAddComplaint}
        title="Add New Complaint"
      />
    </div>
  );
};

function BoxWrapper({ children }) {
  return (
    <div className="bg-white rounded-sm p-4 flex-1 border border-gray-200 flex items-center">
      {children}
    </div>
  );
}

export default Complaint;
