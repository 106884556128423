import React from "react";
import {
  FaUserGraduate,
  FaChalkboardTeacher,
  FaMoneyBillWave,
} from "react-icons/fa";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { DashboardCalendar } from "../shared/DashboardCalendar";
import { LineChartComponent } from "../shared/charts/LineChartComponent";
import { BarChartComponent } from "../shared/charts/BarChartComponent";

// Registering chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement
);

export const TeacherDashboard = () => {
  return (
    <div className="min-h-screen p-6 bg-gray-100">
      {/* Top Header */}
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold text-gray-800">Dashboard</h1>
        <input
          type="text"
          placeholder="Search for students/teachers/documents..."
          className="px-4 py-2 rounded-md border border-gray-300 w-96"
        />
      </div>

      {/* Top Cards */}
      <div className="grid grid-cols-4 gap-6 mb-6">
        <div className="bg-white rounded-lg p-4 shadow-md flex items-center">
          <FaUserGraduate className="text-blue-600 text-3xl mr-4" />
          <div>
            <h2 className="text-lg font-semibold">Students</h2>
            <p className="text-2xl font-bold">12478</p>
          </div>
        </div>

        <div className="bg-white rounded-lg p-4 shadow-md flex items-center">
          <FaChalkboardTeacher className="text-red-600 text-3xl mr-4" />
          <div>
            <h2 className="text-lg font-semibold">Teachers</h2>
            <p className="text-2xl font-bold">478</p>
          </div>
        </div>

        <div className="bg-white rounded-lg p-4 shadow-md flex items-center">
          <FaUserGraduate className="text-yellow-500 text-3xl mr-4" />
          <div>
            <h2 className="text-lg font-semibold">Parents</h2>
            <p className="text-2xl font-bold">8908</p>
          </div>
        </div>

        <div className="bg-white rounded-lg p-4 shadow-md flex items-center">
          <FaMoneyBillWave className="text-green-600 text-3xl mr-4" />
          <div>
            <h2 className="text-lg font-semibold">Earnings</h2>
            <p className="text-2xl font-bold">$42.8k</p>
          </div>
        </div>
      </div>

      {/* Main Dashboard Area */}
      <div className="grid grid-cols-3 gap-6">
        {/* Earnings Chart */}
        <div className="bg-white rounded-lg p-6 shadow-md col-span-2">
          <h3 className="text-gray-700 font-bold mb-4">Total Earnings</h3>
          <Bar
            data={{
              labels: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
              ],
              datasets: [
                {
                  label: "Earnings",
                  data: [
                    30000, 40000, 35000, 45000, 38000, 50000, 60000, 45000,
                    40000, 42000, 45000, 48000,
                  ],
                  backgroundColor: "rgba(53, 162, 235, 0.5)",
                },
                {
                  label: "Expense",
                  data: [
                    20000, 25000, 24000, 30000, 28000, 32000, 40000, 30000,
                    29000, 31000, 32000, 35000,
                  ],
                  backgroundColor: "rgba(255, 99, 132, 0.5)",
                },
              ],
            }}
            options={{ responsive: true }}
          />
        </div>

        {/* Events Calendar */}

        <DashboardCalendar />

        {/* Notice Box and Upcoming Events */}
        <div className="col-span-3 grid grid-cols-2 gap-6 mt-6">
          <div className="bg-white rounded-lg p-6 shadow-md">
            <h3 className="text-gray-700 font-bold mb-4">Notice Board</h3>
            <ul className="space-y-2">
              <li className="border-b pb-2">
                <h4 className="text-gray-800 font-semibold">Holiday Notice</h4>
                <p className="text-gray-600 text-sm">
                  School will be closed on 25th December.
                </p>
              </li>
              <li className="border-b pb-2">
                <h4 className="text-gray-800 font-semibold">Exam Schedule</h4>
                <p className="text-gray-600 text-sm">
                  Final exams will start from 10th January.
                </p>
              </li>
              <li className="border-b pb-2">
                <h4 className="text-gray-800 font-semibold">New Admission</h4>
                <p className="text-gray-600 text-sm">
                  Admissions for 2023-2024 are now open.
                </p>
              </li>
            </ul>
          </div>

          {/* Upcoming Events */}
          <div className="bg-white rounded-lg p-6 shadow-md">
            <h3 className="text-gray-700 font-bold mb-4">Upcoming Events</h3>
            <ul className="space-y-2">
              <li className="border-b pb-2">
                <h4 className="text-gray-800 font-semibold">
                  Annual Sports Day
                </h4>
                <p className="text-gray-600 text-sm">
                  Scheduled for 15th October 2023.
                </p>
              </li>
              <li className="border-b pb-2">
                <h4 className="text-gray-800 font-semibold">
                  Parent-Teacher Meeting
                </h4>
                <p className="text-gray-600 text-sm">
                  To be held on 20th October 2023.
                </p>
              </li>
              <li className="border-b pb-2">
                <h4 className="text-gray-800 font-semibold">
                  Science Exhibition
                </h4>
                <p className="text-gray-600 text-sm">
                  Coming up on 5th November 2023.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Charts Section Next to Notice Board */}
      <div className="grid grid-cols-2 gap-6 mt-6">
        {/* Line Chart */}
        <LineChartComponent />

        {/* Bar Chart */}
        <BarChartComponent />
      </div>
    </div>
  );
};
