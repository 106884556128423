import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Layout } from "./components/shared/Layout";
import { TeacherDashboard } from "./components/teacher/TeacherDashboard";
import { CustomerDashboard } from "./components/customer/CustomerDashboard";

import CustomerLogin from "./components/customer/CustomerLogin";
import { Student } from "./components/customer/Student";
import StudentLogin from "./components/student/StudentLogin";
import StudentDashboard from "./components/student/StudentDashboard";
import Profile from "./components/student/Profile";
import Classes from "./components/student/Classes";
import Notification from "./components/student/Notification";
import Attendance from "./components/student/Attendance";
import Complaint from "./components/student/Complaint";
import AllFeeDetails from "./components/customer/AllFeeDetails";
import DueFeeDetails from "./components/customer/DueFeeDetails";
import AdvancePaidDetails from "./components/customer/AdvancePaidDetails";
import StudentDetails from "./components/customer/StudentDetails.jsx";
// import { ComplaintDetailsModal } from "../modal/ComplaintDetailsModal";
import Notifications from "./components/customer/Notifications";
import { Teachers } from "./components/customer/Teachers";
import NoticeBoard from "./components/customer/NoticeBoard";
import { isAuthenticated } from "./lib/utils/auth";
import TeacherDetails from "./components/customer/TeacherDetails";
import NoticeDetails from "./components/customer/NoticeDetails";
import TeacherLogin from "./components/teacher/TeacherLogin";
import { StudentForTeacher } from "./components/teacher/StudentForTeacher";
import Lectures from "./components/teacher/Lectures";
import NotificationsForTeachers from "./components/teacher/NotificationsForTeachers";
import NoticeBoardForTeachers from "./components/teacher/NoticeBoard";
import StudentDetailsForTeachers from "./components/teacher/StudentDetailsForTeachers";
import StudentNoticeBoard from "./components/student/StudentNoticeBoard.jsx";
import NoticeDetailsForTeacher from "./components/teacher/NoticeDetailsForTeacher";
import { AttendanceForTeacher } from "./components/teacher/AttendanceForTeacher";

const PrivateRoute = ({ element }) => {
  return isAuthenticated() ? element : <Navigate to="/customer-login" />;
};

const App = () => {
  const [isAuth, setIsAuth] = useState(isAuthenticated());

  // Effect to check authentication status on mount or when the localStorage changes
  useEffect(() => {
    const checkAuth = () => setIsAuth(isAuthenticated());
    window.addEventListener("storage", checkAuth);
    return () => window.removeEventListener("storage", checkAuth);
  }, []);

  return (
    <Router>
      <Routes>
        {/* Protected routes */}
        <Route path="/" element={<PrivateRoute element={<Layout />} />}>
          <Route index element={<CustomerDashboard />} />
          <Route path="/student-dashboard" element={<StudentDashboard />} />
          <Route path="/teacher-dashboard" element={<TeacherDashboard />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/classes" element={<Classes />} />
          <Route path="/lectures" element={<Lectures />} />
          <Route
            path="/notificationsForTeachers"
            element={<NotificationsForTeachers />}
          />
          <Route
            path="/noticeboardForTeachers"
            element={<NoticeBoardForTeachers />}
          />

          {/* <Route path="/notice" element={<Notice />} /> */}
          <Route
            path="/attendanceForTeacher"
            element={<AttendanceForTeacher />}
          />
          <Route path="/attendance" element={<Attendance />} />
          <Route path="/complaint" element={<Complaint />} />
          <Route path="/student" element={<Student />} />
          <Route path="/student-teacher" element={<StudentForTeacher />} />
          <Route path="/student/:id" element={<StudentDetails />} />
          <Route
            path="/student-teachers/:id"
            element={<StudentDetailsForTeachers />}
          />
          {/* New Fee Details Routes */}
          <Route path="/fee-details/all" element={<AllFeeDetails />} />
          <Route path="/fee-details/due" element={<DueFeeDetails />} />
          <Route path="/fee-details/advance" element={<AdvancePaidDetails />} />
          {/* New Routes for Notifications, Teachers, and Notice Board */}
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/teachers" element={<Teachers />} />
          <Route path="/teacher/:id" element={<TeacherDetails />} />
          <Route path="/notice-board" element={<NoticeBoard />} />
          <Route path="/studentnoticeboard" element={<StudentNoticeBoard />} />

          <Route path="/notice/:id" element={<NoticeDetails />} />
          <Route
            path="/notice-teacher/:id"
            element={<NoticeDetailsForTeacher />}
          />
        </Route>

        {/* Public routes */}
        <Route
          path="/customer-login"
          element={isAuth ? <Navigate to="/" /> : <CustomerLogin />}
        />
        <Route
          path="/teacher-login"
          element={
            isAuth ? <Navigate to="/teacher-dashboard" /> : <TeacherLogin />
          }
        />
        <Route
          path="/student-login"
          element={
            isAuth ? <Navigate to="/student-dashboard" /> : <StudentLogin />
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
