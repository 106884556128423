import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import classNames from "classnames"; // Importing classnames for dynamic class handling

export const DashboardCalendar = () => {
  const [date, setDate] = useState(new Date());

  return (
    <div className="bg-white rounded-lg p-6 shadow-md">
      <h3 className="text-gray-700 font-bold mb-4">Events Calendar</h3>
      <Calendar
        onChange={setDate}
        value={date}
        className="w-full border-none"
        tileClassName={({ date: tileDate, view }) =>
          classNames(
            "p-2 text-center hover:bg-blue-100 cursor-pointer rounded-lg",
            {
              "text-red-600": view === "month" && tileDate.getDay() === 0, // Sunday tiles in red
              "text-gray-700": view === "month" && tileDate.getDay() !== 0, // Other day tiles in gray
            }
          )
        }
      />
      {/* Show selected date below the calendar */}
      <div className="mt-4 text-gray-700 text-sm">
        Selected Date: {date.toDateString()}
      </div>
    </div>
  );
};
