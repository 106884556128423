import React from "react";
import { FaBook, FaClipboardList, FaGraduationCap } from "react-icons/fa";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { DashboardCalendar } from "../shared/DashboardCalendar";
import { LineChartComponent } from "../shared/charts/LineChartComponent";
import { BarChartComponent } from "../shared/charts/BarChartComponent";

// Registering chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement
);

const StudentDashboard = () => {
  return (
    <div className="min-h-screen p-6 bg-gray-100">
      {/* Top Header */}
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold text-gray-800">Student Dashboard</h1>
        <input
          type="text"
          placeholder="Search for assignments, subjects, documents..."
          className="px-4 py-2 rounded-md border border-gray-300 w-96"
        />
      </div>

      {/* Top Cards */}
      <div className="grid grid-cols-4 gap-6 mb-6">
        <div className="bg-white rounded-lg p-4 shadow-md flex items-center">
          <FaBook className="text-blue-600 text-3xl mr-4" />
          <div>
            <h2 className="text-lg font-semibold">Subjects Enrolled</h2>
            <p className="text-2xl font-bold">6</p>
          </div>
        </div>

        <div className="bg-white rounded-lg p-4 shadow-md flex items-center">
          <FaClipboardList className="text-red-600 text-3xl mr-4" />
          <div>
            <h2 className="text-lg font-semibold">Assignments Pending</h2>
            <p className="text-2xl font-bold">3</p>
          </div>
        </div>

        <div className="bg-white rounded-lg p-4 shadow-md flex items-center">
          <FaGraduationCap className="text-yellow-500 text-3xl mr-4" />
          <div>
            <h2 className="text-lg font-semibold">GPA</h2>
            <p className="text-2xl font-bold">3.8</p>
          </div>
        </div>

        <div className="bg-white rounded-lg p-4 shadow-md flex items-center">
          <FaBook className="text-green-600 text-3xl mr-4" />
          <div>
            <h2 className="text-lg font-semibold">Completed Credits</h2>
            <p className="text-2xl font-bold">32</p>
          </div>
        </div>
      </div>

      {/* Main Dashboard Area */}
      <div className="grid grid-cols-3 gap-6">
        {/* Performance Chart */}
        <div className="bg-white rounded-lg p-6 shadow-md col-span-2">
          <h3 className="text-gray-700 font-bold mb-4">Academic Performance</h3>
          <Bar
            data={{
              labels: [
                "Subject 1",
                "Subject 2",
                "Subject 3",
                "Subject 4",
                "Subject 5",
                "Subject 6",
              ],
              datasets: [
                {
                  label: "Marks",
                  data: [85, 90, 78, 88, 92, 80],
                  backgroundColor: "rgba(53, 162, 235, 0.5)",
                },
                {
                  label: "Attendance (%)",
                  data: [95, 88, 92, 90, 85, 87],
                  backgroundColor: "rgba(255, 99, 132, 0.5)",
                },
              ],
            }}
            options={{ responsive: true }}
          />
        </div>

        {/* Events Calendar */}
        <DashboardCalendar />

        {/* Notice Box and Upcoming Events */}
        <div className="col-span-3 grid grid-cols-2 gap-6 mt-6">
          <div className="bg-white rounded-lg p-6 shadow-md">
            <h3 className="text-gray-700 font-bold mb-4">Notice Board</h3>
            <ul className="space-y-2">
              <li className="border-b pb-2">
                <h4 className="text-gray-800 font-semibold">Assignment Due</h4>
                <p className="text-gray-600 text-sm">
                  Math Assignment 2 is due on 20th September.
                </p>
              </li>
              <li className="border-b pb-2">
                <h4 className="text-gray-800 font-semibold">Exam Notice</h4>
                <p className="text-gray-600 text-sm">
                  Midterm exams start from 5th October.
                </p>
              </li>
              <li className="border-b pb-2">
                <h4 className="text-gray-800 font-semibold">Library</h4>
                <p className="text-gray-600 text-sm">
                  Please return overdue books by 15th September.
                </p>
              </li>
            </ul>
          </div>

          {/* Upcoming Events */}
          <div className="bg-white rounded-lg p-6 shadow-md">
            <h3 className="text-gray-700 font-bold mb-4">Upcoming Events</h3>
            <ul className="space-y-2">
              <li className="border-b pb-2">
                <h4 className="text-gray-800 font-semibold">Cultural Fest</h4>
                <p className="text-gray-600 text-sm">
                  Scheduled for 10th November.
                </p>
              </li>
              <li className="border-b pb-2">
                <h4 className="text-gray-800 font-semibold">Internship Fair</h4>
                <p className="text-gray-600 text-sm">
                  To be held on 15th October.
                </p>
              </li>
              <li className="border-b pb-2">
                <h4 className="text-gray-800 font-semibold">
                  Guest Lecture: AI in Education
                </h4>
                <p className="text-gray-600 text-sm">
                  Coming up on 25th October.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Charts Section Next to Notice Board */}
      <div className="grid grid-cols-2 gap-6 mt-6">
        {/* Line Chart */}
        <LineChartComponent />

        {/* Bar Chart */}
        <BarChartComponent />
      </div>
    </div>
  );
};

export default StudentDashboard;
