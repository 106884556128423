import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import {
  STUDENT_SIDEBAR_LINKS,
  CUSTOMER_SIDEBAR_LINKS,
  TEACHER_SIDEBAR_LINKS,
  DASHBOARD_SIDEBAR_BOTTOM_LINKS,
} from "../../lib/consts/navigation";
import useMediaQuery from "@mui/material/useMediaQuery";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import Typography from "@mui/material/Typography";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  backgroundColor: "#1F2937", // Dark background color (Tailwind bg-gray-800)
  color: "white", // Text color
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: theme.spacing(7),
  [theme.breakpoints.up("sm")]: {
    width: theme.spacing(8),
  },
  backgroundColor: "#1F2937", // Dark background color (Tailwind bg-gray-800)
  color: "white", // Text color
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const DrawerComponent = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const SidebarLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: "inherit",
}));

export default function Sidebar() {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const [open, setOpen] = React.useState(isLargeScreen);
  const [submenuOpen, setSubmenuOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(isLargeScreen);
  }, [isLargeScreen]);

  const handleDrawerOpen = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleSubmenuToggle = () => {
    setSubmenuOpen(!submenuOpen);
  };

  const { pathname } = useLocation();

  const userType = localStorage.getItem("userType");
  const links =
    userType === "student"
      ? STUDENT_SIDEBAR_LINKS
      : userType === "teacher"
        ? TEACHER_SIDEBAR_LINKS
        : CUSTOMER_SIDEBAR_LINKS;

  return (
    <Box sx={{ display: "flex" }}>
      <DrawerComponent variant="permanent" open={open}>
        {/* Header Section with MySchool heading */}
        <DrawerHeader
          sx={{
            justifyContent: open ? "center" : "flex-end",
            padding: "8px",
            backgroundColor: "#111827", // Slightly darker background (Tailwind bg-gray-900)
          }}
        >
          {open && (
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ color: "white", fontWeight: "bold" }}
            >
              MySchool
            </Typography>
          )}
          <IconButton
            onClick={handleDrawerOpen}
            sx={{
              color: "white",
              transform: open ? "rotate(0deg)" : "rotate(180deg)", // Rotate when toggled
              transition: "transform 0.3s ease-in-out",
            }}
          >
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>

        <Divider sx={{ backgroundColor: "#374151" }} />

        <List>
          {links.map((item) => (
            <>
              <ListItem
                key={item.key}
                disablePadding
                sx={{
                  display: "block",
                  backgroundColor:
                    pathname === item.path ? "#ffffff" : "inherit", // Active state color
                  color: pathname === item.path ? "#1F2937" : "#D1D5DB", // Active state text color
                  borderRadius: pathname === item.path ? "8px" : "0", // Add rounded corners when active
                  margin: "5px 10px", // Add some margin
                  "&:hover": {
                    backgroundColor: "#E5E7EB", // Hover state color (Tailwind bg-gray-200)
                    color: "#1F2937", // Text color on hover (Tailwind text-gray-800)
                    borderRadius: "8px", // Rounded corners on hover
                  },
                }}
              >
                <ListItemButton
                  onClick={item.submenu ? handleSubmenuToggle : undefined}
                  component={item.submenu ? undefined : SidebarLink}
                  to={item.submenu ? undefined : item.path}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      color: "inherit",
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.label}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                  {item.submenu ? (
                    submenuOpen ? (
                      <MdExpandLess />
                    ) : (
                      <MdExpandMore />
                    )
                  ) : null}
                </ListItemButton>
              </ListItem>

              {/* Submenu Handling */}
              {item.submenu && (
                <Collapse in={submenuOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.submenu.map((subItem) => (
                      <ListItem
                        key={subItem.key}
                        disablePadding
                        sx={{
                          backgroundColor:
                            pathname === subItem.path ? "#ffffff" : "inherit",
                          color:
                            pathname === subItem.path ? "#1F2937" : "#9CA3AF", // Text color
                          margin: "0 15px",
                          "&:hover": {
                            backgroundColor: "#E5E7EB",
                            color: "#1F2937",
                            borderRadius: "8px",
                          },
                        }}
                      >
                        <ListItemButton
                          component={SidebarLink}
                          to={subItem.path}
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? "initial" : "center",
                            px: 2.5,
                            pl: 4,
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                              color: "inherit",
                            }}
                          >
                            {subItem.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={subItem.label}
                            sx={{ opacity: open ? 1 : 0 }}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              )}
            </>
          ))}
        </List>

        <Divider sx={{ backgroundColor: "#374151" }} />

        {/* Bottom Links */}
        <List>
          {DASHBOARD_SIDEBAR_BOTTOM_LINKS.map((item) => (
            <ListItem
              key={item.key}
              disablePadding
              sx={{
                display: "block",
                backgroundColor: pathname === item.path ? "#ffffff" : "inherit",
                color: pathname === item.path ? "#1F2937" : "#D1D5DB",
                borderRadius: pathname === item.path ? "8px" : "0", // Rounded corners when active
                margin: "5px 10px", // Add some margin
                "&:hover": {
                  backgroundColor: "#E5E7EB", // Hover color (Tailwind bg-gray-200)
                  color: "#1F2937", // Hover text color
                  borderRadius: "8px", // Rounded corners on hover
                },
              }}
            >
              <ListItemButton
                component={SidebarLink}
                to={item.path}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: "inherit",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.label}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </DrawerComponent>
    </Box>
  );
}
