import React, { useState, useEffect } from "react";
import { IoBagHandle } from "react-icons/io5";
import { FaExchangeAlt, FaMoneyBill, FaUserPlus } from "react-icons/fa";
import FeeDetailsModal from "../modal/FeeDetailsModal";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { HiOutlineSearch } from "react-icons/hi";

const base_url = process.env.REACT_APP_BASE_URL_DEVELOPMENT;

export const AllFeeDetails = () => {
  const [feeDetails, setFeeDetails] = useState([]);
  const [selectedFeeDetail, setSelectedFeeDetail] = useState(null);
  const [isFeeModalOpen, setIsFeeModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");

    fetch(`${base_url}/feeCollections`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          navigate("/customer-login");
          return;
        }
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setFeeDetails(data.data);
        console.log("Fetched fee details: ", data);
      })
      .catch((error) => {
        console.error("Error fetching fee details:", error);
        toast.error("Failed to fetch fee details.");
      });
  }, [navigate]);

  const handleRowClick = (feeDetail) => {
    setSelectedFeeDetail(feeDetail);
    setIsFeeModalOpen(true);
    console.log("Selected Fee Detail:", feeDetail);
  };

  const handleUpdateFeeDetails = (updatedFeeDetail) => {
    // Update the specific fee detail in the state
    setFeeDetails((prevFeeDetails) =>
      prevFeeDetails.map((feeDetail) =>
        feeDetail._id === updatedFeeDetail._id ? updatedFeeDetail : feeDetail
      )
    );
    setIsFeeModalOpen(false);
  };

  const handleDeleteFee = (feeId) => {
    const token = localStorage.getItem("token");

    fetch(`${base_url}/feeCollections/${feeId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to delete fee");
        }
        setFeeDetails((prevDetails) =>
          prevDetails.filter((fee) => fee._id !== feeId)
        );
        toast.success("Fee deleted successfully");
        setIsFeeModalOpen(false); // Close modal after deletion
      })
      .catch((error) => {
        console.error("Error deleting fee:", error);
        toast.error("Failed to delete fee.");
      });
  };

  return (
    <div className="flex flex-col gap-4 w-full">
      <ToastContainer />
      {/* Box Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <BoxWrapper>
          <div className="rounded-full h-12 w-12 flex items-center justify-center bg-sky-500">
            <IoBagHandle className="text-2xl text-white" />
          </div>
          <div className="pl-4">
            <span className="text-sm text-gray-500 font-light">Total Fees</span>
            <div className="flex items-center">
              <strong className="text-xl text-gray-700 font-semibold">
                $20,000
              </strong>
            </div>
          </div>
        </BoxWrapper>
        <BoxWrapper>
          <div className="rounded-full h-12 w-12 flex items-center justify-center bg-orange-500">
            <FaUserPlus className="text-2xl text-white" />
          </div>
          <div className="pl-4">
            <span className="text-sm text-gray-500 font-light">
              New Payments
            </span>
            <div className="flex items-center">
              <strong className="text-xl text-gray-700 font-semibold">
                15
              </strong>
            </div>
          </div>
        </BoxWrapper>
        <BoxWrapper>
          <div className="rounded-full h-12 w-12 flex items-center justify-center bg-yellow-500">
            <FaMoneyBill className="text-2xl text-white" />
          </div>
          <div className="pl-4">
            <span className="text-sm text-gray-500 font-light">
              Income Collected
            </span>
            <div className="flex items-center">
              <strong className="text-xl text-gray-700 font-semibold">
                $18,000
              </strong>
            </div>
          </div>
        </BoxWrapper>
        <BoxWrapper>
          <div className="rounded-full h-12 w-12 flex items-center justify-center bg-blue-500">
            <FaExchangeAlt className="text-2xl text-white" />
          </div>
          <div className="pl-4">
            <span className="text-sm text-gray-500 font-light">
              Conversion Rate
            </span>
            <div className="flex items-center">
              <strong className="text-xl text-gray-700 font-semibold">
                75%
              </strong>
            </div>
          </div>
        </BoxWrapper>
      </div>

      {/* Table Section */}
      <div className="bg-white px-4 pt-3 pb-4 rounded-sm border border-gray-200 flex-1">
        <div className="flex flex-col md:flex-row justify-between items-center mb-2">
          <strong className="text-gray-700 font-medium mb-2 md:mb-0">
            All Fee Details
          </strong>
          <div className="relative w-full md:w-auto">
            <HiOutlineSearch
              fontSize={20}
              className="text-gray-400 absolute top-1/2 -translate-y-1/2 left-3"
            />
            <input
              type="text"
              placeholder="Search..."
              className="text-sm focus:outline-none active:outline-none h-10 w-full md:w-[20rem] border border-gray-300 rounded-sm pl-11 pr-4"
            />
          </div>
        </div>
        {/* Table wrapper with fixed height and overflow */}
        <div
          className="overflow-x-auto"
          style={{ maxHeight: "400px", overflowY: "auto" }}
        >
          <table className="min-w-full bg-white border border-gray-300">
            <thead>
              <tr className="bg-gray-100 text-left text-gray-600 uppercase">
                <th className="py-2 px-4 border">Receipt No.</th>
                <th className="py-2 px-4 border">Amount</th>
                <th className="py-2 px-4 border">Payment Method</th>
                <th className="py-2 px-4 border">Transaction ID</th>
                <th className="py-2 px-4 border">Status</th>
                <th className="py-2 px-4 border">Date Paid</th>
              </tr>
            </thead>
            <tbody>
              {feeDetails && feeDetails.length > 0 ? (
                feeDetails.map((feeDetail) => (
                  <tr
                    key={feeDetail._id}
                    onClick={() => handleRowClick(feeDetail)}
                    className="cursor-pointer hover:bg-gray-100"
                  >
                    <td className="py-2 px-4 border">
                      {feeDetail.receiptNumber}
                    </td>
                    <td className="py-2 px-4 border">${feeDetail.amount}</td>
                    <td className="py-2 px-4 border">
                      {feeDetail.paymentMethod}
                    </td>
                    <td className="py-2 px-4 border">
                      {feeDetail.transactionId}
                    </td>
                    <td className="py-2 px-4 border">
                      {feeDetail.paymentStatus}
                    </td>
                    <td className="py-2 px-4 border">
                      {new Date(feeDetail.datePaid).toLocaleDateString()}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center py-4">
                    No fee details found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <FeeDetailsModal
        isOpen={isFeeModalOpen}
        onClose={() => setIsFeeModalOpen(false)}
        Id={selectedFeeDetail?._id}
        onUpdate={handleUpdateFeeDetails}
        onDelete={handleDeleteFee}
      />
    </div>
  );
};

function BoxWrapper({ children }) {
  return (
    <div className="bg-white rounded-sm p-4 flex-1 border border-gray-200 flex items-center">
      {children}
    </div>
  );
}

export default AllFeeDetails;
