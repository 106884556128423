import React, { useState, useEffect } from "react";
import { IoBagHandle } from "react-icons/io5";
import { FaExchangeAlt, FaMoneyBill, FaUserPlus } from "react-icons/fa";
import { AddStudentModal } from "../modal/AddStudentModal";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const base_url = process.env.REACT_APP_BASE_URL_DEVELOPMENT;

export const AttendanceForTeacher = () => {
  const [students, setStudents] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");

    fetch(`${base_url}/teacher/getStudentsForTeacher`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          navigate("/customer-login");
          return;
        }
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setStudents(data.data);
        console.log("Fetched student data: ", data.data);
      })
      .catch((error) => {
        console.error("Error fetching student data:", error);
      });
  }, [navigate]);

  // const handleAddStudent = (newStudent) => {
  //   const token = localStorage.getItem("token");

  //   fetch(`${base_url}/student`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${token}`,
  //     },
  //     body: JSON.stringify(newStudent),
  //   })
  //     .then((response) => {
  //       if (response.status === 401) {
  //         navigate("/customer-login");
  //         return;
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       console.log("Student added: ", data);

  //       // Safeguard for null data or missing user field
  //       if (data && data.data && data.data.user) {
  //         setStudents((prev) => [...prev, data.data.user]);
  //         setIsModalOpen(false);
  //         toast.success("Student added successfully!");
  //       } else {
  //         // Handle the case where the response is not as expected
  //         toast.error("Failed to add student. Invalid response.");
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error adding student:", error);
  //       toast.error("Failed to add student.");
  //     });
  // };

  const handleRowClick = (studentId) => {
    navigate(`/student-teachers/${studentId}`);
  };

  return (
    <div className="flex flex-col gap-4 w-full">
      <ToastContainer />
      {/* Statistics Boxes */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        <BoxWrapper>
          <div className="rounded-full h-12 w-12 flex items-center justify-center bg-sky-500">
            <IoBagHandle className="text-2xl text-white" />
          </div>
          <div className="pl-4">
            <span className="text-sm text-gray-500 font-light">Class</span>
            <div className="flex items-center">
              <select className="text-sm bg-white border border-gray-300 rounded p-1 focus:outline-none focus:ring-2 focus:ring-sky-500">
                <option value="9">Class 9</option>
                <option value="10">Class 10</option>
                {/* Add more options as needed */}
              </select>
            </div>
          </div>
        </BoxWrapper>

        <BoxWrapper>
          <div className="rounded-full h-12 w-12 flex items-center justify-center bg-orange-500">
            <FaUserPlus className="text-2xl text-white" />
          </div>
          <div className="pl-4">
            <span className="text-sm text-gray-500 font-light">Date</span>
            <div className="flex items-center">
              <input
                type="date"
                className="text-sm bg-white border border-gray-300 rounded p-1 focus:outline-none focus:ring-2 focus:ring-orange-500"
              />
            </div>
          </div>
        </BoxWrapper>
      </div>

      <div
        className="bg-white px-4 pt-3 pb-4 rounded-sm border border-gray-200 flex-1"
        style={{ maxHeight: "700px" }}
      >
        <div className="flex justify-between items-center flex-wrap gap-2">
          <strong className="text-gray-700 font-medium">All Students</strong>
        </div>

        {/* Table Section */}
        <div className="w-full overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-300">
            <thead>
              <tr className="bg-gray-100 text-left text-gray-600 uppercase">
                <th className="py-2 px-4 border">Name</th>
                <th className="py-2 px-4 border">08/10/2024</th>
                <th className="py-2 px-4 border">09/10/2024</th>
                <th className="py-2 px-4 border">10/10/2024</th>
                <th className="py-2 px-4 border">11/10/2024</th>
                <th className="py-2 px-4 border">Total Attendance</th>
              </tr>
            </thead>
            <tbody>
              {students && students.length > 0 ? (
                students.map((student) => (
                  <tr
                    key={student?._id}
                    // onClick={() => handleRowClick(student?._id)}
                    className="cursor-pointer hover:bg-gray-100"
                  >
                    <td className="py-2 px-4 border">
                      {student?.firstName} {student?.lastName}
                    </td>
                    <td className="py-2 px-4 border">
                      Mark Attendance
                      <input
                        type="checkbox"
                        className="ml-2 rounded focus:ring-2 focus:ring-blue-500"
                        aria-label="Mark attendance"
                      />
                    </td>

                    <td className="py-2 px-4 border">
                      Mark Attendance
                      <input
                        type="checkbox"
                        className="ml-2 rounded focus:ring-2 focus:ring-blue-500"
                        aria-label="Mark attendance"
                      />
                    </td>
                    <td className="py-2 px-4 border">
                      Mark Attendance
                      <input
                        type="checkbox"
                        className="ml-2 rounded focus:ring-2 focus:ring-blue-500"
                        aria-label="Mark attendance"
                      />
                    </td>
                    <td className="py-2 px-4 border">
                      Mark Attendance
                      <input
                        type="checkbox"
                        className="ml-2 rounded focus:ring-2 focus:ring-blue-500"
                        aria-label="Mark attendance"
                      />
                    </td>
                    <td className="py-2 px-4 border">11/25</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="14" className="text-center py-4">
                    No students found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <AddStudentModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        // onSubmit={handleAddStudent}
        title="Add New Student"
      />
    </div>
  );
};

function BoxWrapper({ children }) {
  return (
    <div className="bg-white rounded-sm p-4 flex-1 border border-gray-200 flex items-center">
      {children}
    </div>
  );
}
