import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import Modal from "react-modal";

// Ensure Modal is mounted properly on your app's root
Modal.setAppElement("#root");

const AddTeacherModal = ({ isOpen, onClose, onSubmit, title }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    subjects: [], // subjects will be handled as an array
    classes: [], // classes will be handled as an array
    address: {
      street: "",
      city: "",
      state: "",
      postalCode: "",
    },
    salary: {
      amount: "",
      currency: "INR",
    },
  });

  const [errors, setErrors] = useState({});

  // Handle input for general fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle input for address fields
  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      address: {
        ...formData.address,
        [name]: value,
      },
    });
  };

  // Handle salary input
  const handleSalaryChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      salary: {
        ...formData.salary,
        [name]: value,
      },
    });
  };

  // Handle subjects as an array
  const handleSubjectsChange = (e) => {
    const subjectsArray = e.target.value
      .split(",")
      .map((subject) => subject.trim());
    setFormData({ ...formData, subjects: subjectsArray });
  };

  // Handle classes as an array
  const handleClassesChange = (e) => {
    const classesArray = e.target.value
      .split(",")
      .map((classItem) => classItem.trim());
    setFormData({ ...formData, classes: classesArray });
  };

  // Form validation
  const validateForm = () => {
    const newErrors = {};

    if (!formData.firstName) newErrors.firstName = "First name is required";
    if (!formData.lastName) newErrors.lastName = "Last name is required";
    if (!formData.email) newErrors.email = "Email is required";

    // Phone number validation
    if (!formData.phone) {
      newErrors.phone = "Phone number is required";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Phone number must be exactly 10 digits";
    }

    if (!formData.address.street) newErrors.street = "Street is required";
    if (!formData.address.city) newErrors.city = "City is required";
    if (!formData.address.state) newErrors.state = "State is required";
    if (!formData.address.postalCode)
      newErrors.postalCode = "Postal code is required";
    if (!formData.salary.amount) newErrors.salary = "Salary amount is required";

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent form from refreshing the page

    const isValid = validateForm(); // Run validation first

    if (isValid) {
      // Submit the form
      onSubmit(formData);

      // Reset the form after submission
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        subjects: [],
        classes: [],
        address: {
          street: "",
          city: "",
          state: "",
          postalCode: "",
        },
        salary: {
          amount: "",
          currency: "INR",
        },
      });

      setErrors({}); // Clear errors after successful submission
      onClose(); // Only close the modal if validation passes
    } else {
      // Keep the modal open if validation fails
      console.log("Validation failed, modal stays open.");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full relative">
        {/* Close Icon */}
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-600 hover:text-gray-900"
        >
          <FaTimes className="text-xl" />
        </button>
        <h2 className="text-xl font-bold mb-4">{title}</h2>
        <form onSubmit={handleSubmit}>
          {/* First Name and Last Name */}
          <div className="flex gap-4 mb-2">
            <div className="w-1/2">
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                value={formData.firstName}
                onChange={handleInputChange}
                className={`w-full p-2 border ${
                  errors.firstName ? "border-red-500" : "border-gray-300"
                }`}
              />
              {errors.firstName && (
                <p className="text-red-500 text-sm">{errors.firstName}</p>
              )}
            </div>
            <div className="w-1/2">
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleInputChange}
                className={`w-full p-2 border ${
                  errors.lastName ? "border-red-500" : "border-gray-300"
                }`}
              />
              {errors.lastName && (
                <p className="text-red-500 text-sm">{errors.lastName}</p>
              )}
            </div>
          </div>

          {/* Email and Phone */}
          <div className="flex gap-4 mb-2">
            <div className="w-1/2">
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleInputChange}
                className={`w-full p-2 border ${
                  errors.email ? "border-red-500" : "border-gray-300"
                }`}
              />
              {errors.email && (
                <p className="text-red-500 text-sm">{errors.email}</p>
              )}
            </div>
            <div className="w-1/2">
              <input
                type="text"
                name="phone"
                placeholder="Phone Number"
                value={formData.phone}
                onChange={handleInputChange}
                className={`w-full p-2 border ${
                  errors.phone ? "border-red-500" : "border-gray-300"
                }`}
              />
              {errors.phone && (
                <p className="text-red-500 text-sm">{errors.phone}</p>
              )}
            </div>
          </div>

          {/* Subjects and Classes */}
          <div className="flex gap-4 mb-2">
            <div className="w-1/2">
              <input
                type="text"
                name="subjects"
                placeholder="Subjects (comma separated)"
                value={formData.subjects.join(", ")}
                onChange={handleSubjectsChange}
                className="w-full p-2 border border-gray-300"
              />
            </div>
            <div className="w-1/2">
              <input
                type="text"
                name="classes"
                placeholder="Classes (comma separated)"
                value={formData.classes.join(", ")}
                onChange={handleClassesChange}
                className="w-full p-2 border border-gray-300"
              />
            </div>
          </div>

          {/* Address Section */}
          <h3 className="text-lg font-semibold mb-2 mt-4">Address</h3>
          <div className="flex gap-4 mb-2">
            <div className="w-1/2">
              <input
                type="text"
                name="street"
                placeholder="Street"
                value={formData.address.street}
                onChange={handleAddressChange}
                className={`w-full p-2 border ${
                  errors.street ? "border-red-500" : "border-gray-300"
                }`}
              />
              {errors.street && (
                <p className="text-red-500 text-sm">{errors.street}</p>
              )}
            </div>
            <div className="w-1/2">
              <input
                type="text"
                name="city"
                placeholder="City"
                value={formData.address.city}
                onChange={handleAddressChange}
                className={`w-full p-2 border ${
                  errors.city ? "border-red-500" : "border-gray-300"
                }`}
              />
              {errors.city && (
                <p className="text-red-500 text-sm">{errors.city}</p>
              )}
            </div>
          </div>
          <div className="flex gap-4 mb-2">
            <div className="w-1/2">
              <input
                type="text"
                name="state"
                placeholder="State"
                value={formData.address.state}
                onChange={handleAddressChange}
                className={`w-full p-2 border ${
                  errors.state ? "border-red-500" : "border-gray-300"
                }`}
              />
              {errors.state && (
                <p className="text-red-500 text-sm">{errors.state}</p>
              )}
            </div>
            <div className="w-1/2">
              <input
                type="text"
                name="postalCode"
                placeholder="Postal Code"
                value={formData.address.postalCode}
                onChange={handleAddressChange}
                className={`w-full p-2 border ${
                  errors.postalCode ? "border-red-500" : "border-gray-300"
                }`}
              />
              {errors.postalCode && (
                <p className="text-red-500 text-sm">{errors.postalCode}</p>
              )}
            </div>
          </div>

          {/* Salary Amount */}
          <div className="mb-2">
            <input
              type="number"
              name="amount"
              placeholder="Salary Amount"
              value={formData.salary.amount}
              onChange={handleSalaryChange}
              className={`w-full p-2 border ${
                errors.salary ? "border-red-500" : "border-gray-300"
              }`}
            />
            {errors.salary && (
              <p className="text-red-500 text-sm">{errors.salary}</p>
            )}
          </div>

          {/* Submit and Cancel */}
          <div className="flex justify-end mt-4">
            <button
              className="mr-2 bg-gray-500 text-white px-4 py-2 rounded"
              type="button" // This ensures the cancel button doesn't trigger form submission
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded"
              type="submit" // Submit button to trigger handleSubmit
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddTeacherModal;
