import React from "react";
import { Line, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export const LineChartComponent = () => {
  const data = {
    labels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    datasets: [
      {
        label: "Dataset 1",
        data: [40, 30, 50, 25, 70, 55, 35],
        fill: true,
        backgroundColor: "rgba(34, 197, 94, 0.2)", // Tailwind green-500 with opacity
        borderColor: "rgba(34, 197, 94, 1)", // Tailwind green-500
        tension: 0.3, // Smoother line
        pointBorderColor: "rgba(34, 197, 94, 1)",
        pointBackgroundColor: "rgba(34, 197, 94, 0.6)",
        pointRadius: 3,
      },
      {
        label: "Dataset 2",
        data: [20, 45, 30, 60, 40, 70, 50],
        fill: true,
        backgroundColor: "rgba(16, 185, 129, 0.2)", // Tailwind green-400 with opacity
        borderColor: "rgba(16, 185, 129, 1)", // Tailwind green-400
        tension: 0.3,
        pointBorderColor: "rgba(16, 185, 129, 1)",
        pointBackgroundColor: "rgba(16, 185, 129, 0.6)",
        pointRadius: 3,
      },
    ],
  };

  return (
    <div
      className="bg-white p-4 rounded-lg shadow-md"
      style={{ height: "250px" }}
    >
      <Line
        data={data}
        options={{ responsive: true, maintainAspectRatio: false }}
      />
    </div>
  );
};
