// AddNoticeModal.js
import React from "react";
import { FaTimes } from "react-icons/fa";

const AddNoticeModal = ({
  noticeData,
  handleInputChange,
  handleFileChange,
  handleCreateNotice,
  closeModal,
}) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg w-full max-w-2xl shadow-lg">
        <div className="flex flex-col justify-between mb-4 w-full relative">
          <button
            className="absolute top-0 right-0 text-gray-600"
            onClick={closeModal}
          >
            <FaTimes className="text-xl" />
          </button>
          <h2 className="text-2xl font-bold mb-2 text-center text-gray-800 w-full">
            Create Notice
          </h2>

          {/* Divider Line */}
          <hr className="border-t-4 border-gray-800 mb-6" />

          {/* <button
            className="text-gray-500 hover:text-gray-800"
            onClick={closeModal}
          >
            ✖
          </button> */}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-4">
          <div className="col-span-2">
            <label className="font-semibold">Title</label>
            <input
              type="text"
              name="title"
              value={noticeData.title}
              onChange={handleInputChange}
              className="border p-2 rounded w-full"
              placeholder="Enter notice title"
            />
          </div>
          <div>
            <label className="font-semibold">Recipient Type</label>
            <select
              name="recipientType"
              value={noticeData.recipientType}
              onChange={handleInputChange}
              className="border p-2 rounded w-full"
            >
              <option value="All">All</option>
              <option value="Teachers">Teachers</option>
              <option value="Students">Students</option>
              <option value="Non-Teaching Staff">Non-Teaching Staff</option>
            </select>
          </div>
          <div>
            <label className="font-semibold">Priority</label>
            <select
              name="priority"
              value={noticeData.priority}
              onChange={handleInputChange}
              className="border p-2 rounded w-full"
            >
              <option value="Normal">Normal</option>
              <option value="Medium">Medium</option>
              <option value="High">High</option>
            </select>
          </div>
          <div>
            <label className="font-semibold">Expiry Date</label>
            <input
              type="date"
              name="expiresAt"
              value={noticeData.expiresAt}
              onChange={handleInputChange}
              className="border p-2 rounded w-full"
            />
          </div>
          <div>
            <label className="font-semibold">Attachments</label>
            <input
              type="file"
              onChange={handleFileChange}
              className="border p-2 rounded w-full"
            />
          </div>
          <div className="col-span-2">
            <label className="font-semibold">Message</label>
            <textarea
              name="message"
              value={noticeData.message}
              onChange={handleInputChange}
              className="border p-2 rounded w-full"
              placeholder="Enter notice message"
              rows="4"
            />
          </div>
        </div>

        {/* Create Notice Button */}
        <div className="flex justify-end mt-4">
          <button
            onClick={handleCreateNotice}
            className="w-full bg-gradient-to-r from-gray-500 to-gray-900 text-white px-4 py-2 rounded hover:from-gray-700 hover:to-gray-800"
          >
            Create Notice
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddNoticeModal;
