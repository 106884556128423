import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const base_url = process.env.REACT_APP_BASE_URL_DEVELOPMENT;

const NoticeDetails = () => {
  const { id } = useParams();
  const [notice, setNotice] = useState(null);
  const [updatedNotice, setUpdatedNotice] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNoticeDetails = async () => {
      const token = localStorage.getItem("token");

      try {
        const response = await fetch(`${base_url}/noticeboard/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 401) {
          toast.error("Session expired, please login again.");
          navigate("/customer-login");
          return;
        }

        if (!response.ok) throw new Error("Failed to fetch notice details.");

        const data = await response.json();
        setNotice(data.data);
        // Set initial fields for editing
        setUpdatedNotice({
          title: data.data.title,
          message: data.data.message,
          recipientType: data.data.recipientType,
          priority: data.data.priority,
          expiresAt: data.data.expiresAt,
          attachments: data.data.attachments,
        });
        setLoading(false);
      } catch (error) {
        console.error("Error fetching notice details:", error);
        toast.error("Failed to fetch notice details.");
        setLoading(false);
      }
    };

    fetchNoticeDetails();
  }, [id, navigate]);

  const handleSave = async () => {
    const token = localStorage.getItem("token");

    // Ensure that the attachments array does not contain the _id field
    const cleanedAttachments = updatedNotice.attachments.map((attachment) => ({
      fileName: attachment.fileName,
      fileUrl: attachment.fileUrl,
    }));

    // Create a new object without the _id field in attachments
    const cleanedNotice = {
      ...updatedNotice,
      attachments: cleanedAttachments,
    };

    try {
      const response = await fetch(`${base_url}/noticeboard/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(cleanedNotice),
      });

      if (!response.ok) throw new Error("Failed to update notice");

      const data = await response.json();
      setNotice(data.data);
      setIsEditMode(false);
      toast.success("Notice updated successfully.");
    } catch (error) {
      console.error("Error updating notice:", error);
      toast.error("Failed to update notice.");
    }
  };

  const handleDelete = async () => {
    const token = localStorage.getItem("token");
    if (window.confirm("Are you sure you want to delete this notice?")) {
      try {
        const response = await fetch(`${base_url}/noticeboard/${id}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) throw new Error("Failed to delete notice");

        toast.success("Notice deleted successfully");
        navigate("/notice-board");
      } catch (error) {
        console.error("Error deleting notice:", error);
        toast.error("Failed to delete notice.");
      }
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const newAttachment = {
        fileName: file.name,
        fileUrl: URL.createObjectURL(file),
      };
      setUpdatedNotice((prevData) => ({
        ...prevData,
        attachments: [newAttachment], // Replacing with the new attachment
      }));
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!notice) {
    return <div>No notice data available.</div>;
  }

  return (
    <div className="flex flex-col items-center h-screen p-4">
      <ToastContainer />
      {!isEditMode && (
        <div className="w-full flex justify-end mb-4">
          <button
            className="bg-gradient-to-r from-gray-400 to-gray-700 hover:from-gray-500 hover:to-gray-900 text-white font-semibold py-2 px-6 rounded-full shadow-md hover:shadow-lg transition-all duration-300 ease-in-out"
            onClick={() => navigate("/notice-board")}
          >
            Back to NoticeBoard
          </button>
        </div>
      )}

      <div
        className={`shadow-md rounded-lg p-6 w-[90%] mb-6 ${
          notice.priority === "High"
            ? "bg-red-100 border-l-4 border-red-500"
            : notice.priority === "Medium"
              ? "bg-yellow-100 border-l-4 border-yellow-500"
              : "bg-green-100 border-l-4 border-green-500"
        }`}
      >
        <h1 className="text-2xl font-bold mb-6 text-center text-blue-600">
          Notice Details
        </h1>

        <div className="grid grid-cols-2 gap-x-8 gap-y-6">
          {/* Title */}
          <div className="flex items-center col-span-2">
            <strong className="whitespace-nowrap">Title:</strong>
            {isEditMode ? (
              <input
                type="text"
                className="ml-2 border-b-2 border-blue-500 focus:outline-none w-full"
                value={updatedNotice.title}
                onChange={(e) =>
                  setUpdatedNotice({
                    ...updatedNotice,
                    title: e.target.value,
                  })
                }
              />
            ) : (
              <span className="ml-2">{notice.title}</span>
            )}
          </div>

          {/* Recipient Type */}
          <div className="flex items-center">
            <strong className="whitespace-nowrap">Recipient Type:</strong>
            {isEditMode ? (
              <input
                type="text"
                className="ml-2 border-b-2 border-blue-500 focus:outline-none w-full"
                value={updatedNotice.recipientType}
                onChange={(e) =>
                  setUpdatedNotice({
                    ...updatedNotice,
                    recipientType: e.target.value,
                  })
                }
              />
            ) : (
              <span className="ml-2">{notice.recipientType}</span>
            )}
          </div>

          {/* Priority */}
          <div className="flex items-center">
            <strong className="whitespace-nowrap">Priority:</strong>
            {isEditMode ? (
              <select
                className="ml-2 border-b-2 border-blue-500 focus:outline-none w-full"
                value={updatedNotice.priority}
                onChange={(e) =>
                  setUpdatedNotice({
                    ...updatedNotice,
                    priority: e.target.value,
                  })
                }
              >
                <option value="High">High</option>
                <option value="Medium">Medium</option>
                <option value="Normal">Normal</option>
              </select>
            ) : (
              <span className="ml-2">{notice.priority}</span>
            )}
          </div>

          {/* Expiry Date and Attachments */}
          {/* Expiry Date and Attachments */}
          <div className="flex items-center col-span-2 gap-x-4">
            <div className="flex items-center flex-1">
              <strong className="whitespace-nowrap mr-2">Expires At:</strong>
              {isEditMode ? (
                <input
                  type="date"
                  className="border-b-2 border-blue-500 focus:outline-none w-full"
                  value={
                    updatedNotice.expiresAt
                      ? new Date(updatedNotice.expiresAt)
                          .toISOString()
                          .split("T")[0]
                      : ""
                  }
                  onChange={(e) =>
                    setUpdatedNotice({
                      ...updatedNotice,
                      expiresAt: e.target.value, // Store in YYYY-MM-DD format
                    })
                  }
                />
              ) : (
                <span className="ml-2">
                  {new Date(notice.expiresAt).toLocaleDateString()}{" "}
                  {/* Display only the date */}
                </span>
              )}
            </div>
            <div className="flex items-center flex-1">
              <strong className="whitespace-nowrap mr-2">Attachments:</strong>
              {isEditMode ? (
                <input
                  type="file"
                  className="w-full"
                  onChange={handleFileChange}
                />
              ) : notice.attachments.length > 0 ? (
                <ul className="ml-2 list-disc">
                  {notice.attachments.map((attachment, idx) => (
                    <li key={idx}>
                      <a
                        href={attachment.fileUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 underline"
                      >
                        {attachment.fileName}
                      </a>
                    </li>
                  ))}
                </ul>
              ) : (
                <span className="ml-2">None</span>
              )}
            </div>
          </div>

          {/* Message */}
          <div className="flex items-center col-span-2">
            <strong className="whitespace-nowrap">Message:</strong>
            {isEditMode ? (
              <textarea
                className="ml-2 border-b-2 border-blue-500 focus:outline-none w-full"
                value={updatedNotice.message}
                onChange={(e) =>
                  setUpdatedNotice({
                    ...updatedNotice,
                    message: e.target.value,
                  })
                }
              />
            ) : (
              <span className="ml-2">{notice.message}</span>
            )}
          </div>
        </div>

        <div className="flex justify-end mt-6 space-x-2">
          {isEditMode ? (
            <>
              <button
                className="bg-gradient-to-r from-blue-400 to-blue-600 text-white px-4 py-2 rounded hover:from-blue-500 hover:to-blue-700"
                onClick={handleSave}
              >
                Save
              </button>
              <button
                className="bg-gradient-to-r from-gray-400 to-gray-600 text-white px-4 py-2 rounded hover:from-gray-500 hover:to-gray-700"
                onClick={() => setIsEditMode(false)}
              >
                Cancel
              </button>
            </>
          ) : (
            <>
              <button
                className="bg-gradient-to-r from-gray-400 to-gray-700 hover:from-gray-500 hover:to-gray-900 text-white font-semibold py-2 px-6 rounded-md shadow-md hover:shadow-lg transition-all duration-300 ease-in-out"
                onClick={() => setIsEditMode(true)}
              >
                Edit Notice
              </button>
              <button
                className="bg-gradient-to-r from-red-400 to-red-600 text-white px-4 py-2 rounded hover:from-red-500 hover:to-red-700"
                onClick={handleDelete}
              >
                Delete Notice
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default NoticeDetails;
