// NoticeBoard.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddNoticeModal from "../modal/AddNoticeModal"; // Import the new component

const NoticeBoardForTeachers = () => {
  const navigate = useNavigate();

  const [notices, setNotices] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [noticeData, setNoticeData] = useState({
    title: "",
    message: "",
    recipientType: "All",
    priority: "Normal",
    attachments: [],
    expiresAt: "",
  });

  const base_url = process.env.REACT_APP_BASE_URL_DEVELOPMENT; // Ensure this is set in your .env file

  // Fetch notices when the component mounts
  useEffect(() => {
    const fetchNotices = async () => {
      const token = localStorage.getItem("token");

      try {
        const response = await fetch(`${base_url}/teacher/noticeboard`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 401) {
          toast.error("Session expired, please login again.");
          return;
        }

        if (!response.ok) throw new Error("Failed to fetch notices");

        const data = await response.json();
        setNotices(data?.data || []);
      } catch (error) {
        console.error("Error fetching notices:", error);
        toast.error("Failed to fetch notices.");
      } finally {
        setLoading(false);
      }
    };

    fetchNotices();
  }, [base_url]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNoticeData({
      ...noticeData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const newAttachment = {
        fileName: file.name,
        fileUrl: URL.createObjectURL(file),
      };
      setNoticeData((prevData) => ({
        ...prevData,
        attachments: [...prevData.attachments, newAttachment],
      }));
    }
  };

  const handleCreateNotice = async () => {
    if (!noticeData.title || !noticeData.message || !noticeData.expiresAt) {
      toast.error("Please fill in all required fields.");
      return;
    }

    const token = localStorage.getItem("token");

    try {
      const response = await fetch(`${base_url}/noticeboard`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(noticeData),
      });

      if (!response.ok) throw new Error("Failed to create notice");

      const createdNotice = await response.json();
      setNotices([...notices, createdNotice.data]);
      toast.success("Notice created successfully!");

      // Close modal and reset form
      setIsModalOpen(false);
      setNoticeData({
        title: "",
        message: "",
        recipientType: "All",
        priority: "Normal",
        attachments: [],
        expiresAt: "",
      });
    } catch (error) {
      console.error("Error creating notice:", error);
      toast.error("Failed to create notice.");
    }
  };

  return (
    <div className="p-6 max-w-6xl mx-auto">
      <ToastContainer />
      {/* Notice Board Title and Create Button */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold text-blue-600">Notice Board</h1>
      </div>

      {/* Notice List */}
      {loading ? (
        <p>Loading notices...</p>
      ) : notices.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {notices.map((notice, index) => (
            <div
              key={index}
              className={`p-4 rounded-lg shadow-md cursor-pointer ${
                notice.priority === "High"
                  ? "bg-red-100 border-l-4 border-red-500"
                  : notice.priority === "Medium"
                    ? "bg-yellow-100 border-l-4 border-yellow-500"
                    : "bg-green-100 border-l-4 border-green-500"
              }`}
              onClick={() => navigate(`/notice/${notice._id}`)} // Navigating to NoticeDetails page
            >
              <h3 className="text-lg font-semibold">{notice.title}</h3>
              <p className="text-sm text-gray-700">{notice.message}</p>
              <p className="text-xs text-gray-500 mt-2">
                <strong>Recipient:</strong> {notice.recipientType}
              </p>
              <p className="text-xs text-gray-500">
                <strong>Expires At:</strong>{" "}
                {new Date(notice.expiresAt).toLocaleString()}
              </p>
              {notice.attachments.length > 0 && (
                <div className="mt-2">
                  <strong>Attachments:</strong>
                  <ul className="list-disc pl-4">
                    {notice.attachments.map((attachment, idx) => (
                      <li key={idx}>
                        <a
                          href={attachment.fileUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 underline"
                        >
                          {attachment.fileName}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <p className="text-gray-500">No notices available.</p>
      )}

      {/* Modal for Creating Notice */}
      {isModalOpen && (
        <AddNoticeModal
          noticeData={noticeData}
          handleInputChange={handleInputChange}
          handleFileChange={handleFileChange}
          handleCreateNotice={handleCreateNotice}
          closeModal={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
};

export default NoticeBoardForTeachers;
