import React from "react";
import { Line, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export const BarChartComponent = () => {
  const data = {
    labels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    datasets: [
      {
        label: "Dataset 1",
        data: [30, 50, 40, 60, 45, 65, 30],
        backgroundColor: "rgba(34, 197, 94, 0.6)", // Tailwind green-500 with opacity
        borderColor: "rgba(34, 197, 94, 1)",
        borderWidth: 1,
      },
      {
        label: "Dataset 2",
        data: [40, 35, 60, 50, 70, 55, 40],
        backgroundColor: "rgba(16, 185, 129, 0.6)", // Tailwind green-400 with opacity
        borderColor: "rgba(16, 185, 129, 1)",
        borderWidth: 1,
      },
    ],
  };

  return (
    <div
      className="bg-white p-4 rounded-lg shadow-md"
      style={{ height: "250px" }}
    >
      <Bar
        data={data}
        options={{ responsive: true, maintainAspectRatio: false }}
      />
    </div>
  );
};
