import React, { useState, useEffect } from "react";
import { IoBagHandle } from "react-icons/io5";
import { FaExchangeAlt, FaMoneyBill, FaUserPlus } from "react-icons/fa";
import AddTeacherModal from "../modal/AddTeacherModal";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const base_url = process.env.REACT_APP_BASE_URL_DEVELOPMENT;

export const Teachers = () => {
  const [teachers, setTeachers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");

    fetch(`${base_url}/teacher`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          navigate("/customer-login");
          return;
        }
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setTeachers(data.data);
        console.log("Fetched teacher data: ", data);
      })
      .catch((error) => {
        console.error("Error fetching teacher data:", error);
      });
  }, [navigate]);

  const handleAddTeacher = (newTeacher) => {
    const token = localStorage.getItem("token");

    fetch(`${base_url}/teacher`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(newTeacher),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.success) {
          // Teacher added successfully
          setTeachers((prev) => [...prev, data.data.user]);
          setIsModalOpen(false);
          toast.success("Teacher added successfully!");
        } else {
          // Show server error as a toast notification
          toast.error(data.message || "Failed to add teacher.");
        }
      })
      .catch((error) => {
        console.error("Error adding teacher:", error);
        // Display a general error message in case of a network issue or unexpected error
        toast.error("Failed to add teacher. Please try again.");
      });
  };

  const handleRowClick = (teacherId) => {
    navigate(`/teacher/${teacherId}`);
  };

  return (
    <div className="flex flex-col gap-4 w-full">
      <ToastContainer />
      <div className="flex gap-4">
        <BoxWrapper>
          <div className="rounded-full h-12 w-12 flex items-center justify-center bg-sky-500">
            <IoBagHandle className="text-2xl text-white" />
          </div>
          <div className="pl-4">
            <span className="text-sm text-gray-500 font-light">
              New Teachers
            </span>
            <div className="flex items-center">
              <strong className="text-xl text-gray-700 font-semibold">
                1,345
              </strong>
            </div>
          </div>
        </BoxWrapper>
        <BoxWrapper>
          <div className="rounded-full h-12 w-12 flex items-center justify-center bg-orange-500">
            <FaUserPlus className="text-2xl text-white" />
          </div>
          <div className="pl-4">
            <span className="text-sm text-gray-500 font-light">New Hires</span>
            <div className="flex items-center">
              <strong className="text-xl text-gray-700 font-semibold">
                500
              </strong>
            </div>
          </div>
        </BoxWrapper>
        <BoxWrapper>
          <div className="rounded-full h-12 w-12 flex items-center justify-center bg-yellow-500">
            <FaMoneyBill className="text-2xl text-white" />
          </div>
          <div className="pl-4">
            <span className="text-sm text-gray-500 font-light">
              Total Salary
            </span>
            <div className="flex items-center">
              <strong className="text-xl text-gray-700 font-semibold">
                $250,000
              </strong>
            </div>
          </div>
        </BoxWrapper>
        <BoxWrapper>
          <div className="rounded-full h-12 w-12 flex items-center justify-center bg-blue-500">
            <FaExchangeAlt className="text-2xl text-white" />
          </div>
          <div className="pl-4">
            <span className="text-sm text-gray-500 font-light">
              Conversion Rate
            </span>
            <div className="flex items-center">
              <strong className="text-xl text-gray-700 font-semibold">
                90%
              </strong>
            </div>
          </div>
        </BoxWrapper>
      </div>

      <div
        className="bg-white px-4 pt-3 pb-4 rounded-sm border border-gray-200 flex-1"
        style={{ maxHeight: "700px" }}
      >
        <div className="flex justify-between items-center">
          <strong className="text-gray-700 font-medium">Teachers</strong>
          <button
            className="bg-gradient-to-r from-gray-400 to-gray-700 hover:from-gray-500 hover:to-gray-900 text-white font-semibold py-2 px-6 rounded-full shadow-md hover:shadow-lg transition-all duration-300 ease-in-out"
            onClick={() => setIsModalOpen(true)}
          >
            Add Teacher
          </button>
        </div>

        {/* Table Section */}
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-300">
            <thead>
              <tr className="bg-gray-100 text-left text-gray-600 uppercase">
                <th className="py-2 px-4 border">Name</th>
                <th className="py-2 px-4 border">Hire Date</th>
                <th className="py-2 px-4 border">Address</th>
                <th className="py-2 px-4 border">Department</th>
                <th className="py-2 px-4 border">Contact No.</th>
              </tr>
            </thead>
            <tbody>
              {teachers && teachers.length > 0 ? (
                teachers.map((teacher) => (
                  <tr
                    key={teacher?._id}
                    onClick={() => handleRowClick(teacher?._id)}
                    className="cursor-pointer hover:bg-gray-100"
                  >
                    <td className="py-2 px-4 border">
                      {teacher?.firstName && teacher?.lastName
                        ? `${teacher.firstName} ${teacher.lastName}`
                        : "Unknown"}
                    </td>
                    <td className="py-2 px-4 border">
                      {new Date(teacher?.createdAt).toLocaleDateString()}
                    </td>
                    <td className="py-2 px-4 border">
                      {teacher?.address
                        ? `${teacher.address.street}, ${teacher.address.city}, ${teacher.address.state}, ${teacher.address.postalCode}`
                        : "Unknown"}
                    </td>
                    <td className="py-2 px-4 border">
                      {teacher?.subjects
                        ? teacher.subjects.join(", ")
                        : "Unknown"}
                    </td>
                    <td className="py-2 px-4 border">
                      {teacher?.phone || "Unknown"}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center py-4">
                    No teachers found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <AddTeacherModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleAddTeacher}
        title="Add New Teacher"
      />
    </div>
  );
};

function BoxWrapper({ children }) {
  return (
    <div className="bg-white rounded-sm p-4 flex-1 border border-gray-200 flex items-center">
      {children}
    </div>
  );
}

export default Teachers;
