import {
  HiOutlineViewGrid,
  HiOutlineCog,
  HiOutlineQuestionMarkCircle,
} from "react-icons/hi";
import { PiStudentBold } from "react-icons/pi";
import {
  MdOutlineClass,
  MdPersonOutline,
  MdOutlineNotifications,
  MdReportProblem,
  MdOutlineAccessTime,
  MdOutlineMonetizationOn,
  MdOutlinePeopleAlt,
  MdOutlineDashboard,
  MdOutlineReceipt,
} from "react-icons/md";
import { BiRupee } from "react-icons/bi"; // Importing Indian Rupee Icon

// Student Sidebar Links
export const STUDENT_SIDEBAR_LINKS = [
  {
    key: "dashboard",
    label: "Dashboard",
    path: "/student-dashboard",
    icon: <HiOutlineViewGrid />,
  },
  {
    key: "profile",
    label: "Profile",
    path: "/profile",
    icon: <MdPersonOutline />,
  },
  {
    key: "classes",
    label: "Classes",
    path: "/classes",
    icon: <MdOutlineClass />,
  },
  {
    key: "notification",
    label: "Notification",
    path: "/notification",
    icon: <MdOutlineNotifications />,
  },
  {
    key: "noticboard",
    label: "NoticeBoard",
    path: "/studentnoticeboard",
    icon: <MdOutlineNotifications />,
  },

  {
    key: "attendance",
    label: "Attendance",
    path: "/attendance",
    icon: <MdOutlineAccessTime />,
  },
  {
    key: "complaint",
    label: "Complaint",
    path: "/complaint",
    icon: <MdReportProblem />,
  },
];

// Customer Sidebar Links
export const CUSTOMER_SIDEBAR_LINKS = [
  {
    key: "dashboard",
    label: "Dashboard",
    path: "/",
    icon: <HiOutlineViewGrid />,
  },
  {
    key: "student",
    label: "Student",
    path: "/student",
    icon: <PiStudentBold />,
  },
  {
    key: "feeDetails",
    label: "Fee Details",
    icon: <MdOutlineMonetizationOn />,
    submenu: [
      {
        key: "allFeeDetails",
        label: "All Fee Details",
        path: "/fee-details/all",
        icon: <MdOutlineReceipt />, // Icon for all fee details
      },
      {
        key: "dueFeeDetails",
        label: "Due Fee Details",
        path: "/fee-details/due",
        icon: <BiRupee />, // Indian Rupee icon for due fee details
      },
      {
        key: "advancePaidDetails",
        label: "Advance Paid Details",
        path: "/fee-details/advance",
        icon: <BiRupee />, // Indian Rupee icon for advance paid details
      },
    ],
  },
  {
    key: "notifications",
    label: "Notifications",
    path: "/notifications",
    icon: <MdOutlineNotifications />,
  },
  {
    key: "teachers",
    label: "Teachers",
    path: "/teachers",
    icon: <MdOutlinePeopleAlt />,
  },
  {
    key: "noticeBoard",
    label: "Notice Board",
    path: "/notice-board",
    icon: <MdOutlineDashboard />,
  },
];

// Teacher Sidebar Links
export const TEACHER_SIDEBAR_LINKS = [
  {
    key: "dashboard",
    label: "Dashboard",
    path: "/",
    icon: <HiOutlineViewGrid />,
  },
  {
    key: "lectures",
    label: "Lectures",
    path: "/lectures",
    icon: <MdOutlineDashboard />,
  },
  {
    key: "student",
    label: "Student",
    path: "/student-teacher",
    icon: <PiStudentBold />,
  },
  {
    key: "notifications",
    label: "Notifications",
    path: "/notificationsForTeachers",
    icon: <MdOutlineNotifications />,
  },
  {
    key: "noticeBoard",
    label: "Notice Board",
    path: "/noticeboardForTeachers",
    icon: <MdOutlineDashboard />,
  },
  {
    key: "attendance",
    label: "Attendance",
    path: "/attendanceForTeacher",
    icon: <MdOutlineDashboard />,
  },
  {
    key: "attendance",
    label: "Classes",
    path: "/classes",
    icon: <MdOutlineDashboard />,
  },
  {
    key: "attendance",
    label: "Subjects",
    path: "/subjects",
    icon: <MdOutlineDashboard />,
  },
];

// Bottom Links (Common for All)
export const DASHBOARD_SIDEBAR_BOTTOM_LINKS = [
  {
    key: "settings",
    label: "Settings",
    path: "/settings",
    icon: <HiOutlineCog />,
  },
  {
    key: "support",
    label: "Help & Support",
    path: "/support",
    icon: <HiOutlineQuestionMarkCircle />,
  },
];
