import React, { useState } from "react";
import { FaTimes } from "react-icons/fa"; // Import close icon
import Modal from "react-modal";
import { toast } from "react-toastify"; // Optional: Use for notifications

// Ensure Modal is mounted properly on your app's root
Modal.setAppElement("#root");

const AddFeeModal = ({ isOpen, onClose, onSubmit }) => {
  const [newFee, setNewFee] = useState({
    feeMonth: "January",
    year: "2024-25",
    amount: "",
    paymentMethod: "Credit Card",
    remarks: "",
    paymentStatus: "Paid",
    dueDate: "",
    lateFee: 0,
    discount: 0,
    receiptNumber: "",
    paymentFor: "Hostel",
    academicYear: "2024-25",
  });

  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewFee({ ...newFee, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Set loading state
    setLoading(true);

    // Call onSubmit (which comes from FeeTable) with the newFee data
    onSubmit(newFee);

    // Reset form and close modal
    setNewFee({
      feeMonth: "January",
      year: "2024-25",
      amount: "",
      paymentMethod: "Credit Card",
      remarks: "",
      paymentStatus: "Paid",
      dueDate: "",
      lateFee: 0,
      discount: 0,
      receiptNumber: "",
      paymentFor: "Hostel",
      academicYear: "2024-25",
    });
    setLoading(false);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Add Fee"
      className="bg-white p-6 rounded-lg shadow-md w-full max-w-3xl mx-auto mt-10 relative"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
    >
      {/* Close Icon */}
      <button
        onClick={onClose}
        className="absolute top-3 right-3 text-gray-600 hover:text-gray-900"
      >
        <FaTimes className="text-xl" />
      </button>

      <h2 className="text-2xl font-bold mb-6 text-center">Add Fee</h2>

      <form onSubmit={handleSubmit}>
        {/* First Row: Fee Month and Academic Year */}
        <div className="flex gap-4 mb-4">
          <div className="w-1/2">
            <label className="block text-gray-700">Fee Month</label>
            <select
              name="feeMonth"
              value={newFee.feeMonth}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded"
              required
            >
              <option value="January">January</option>
              <option value="February">February</option>
              <option value="March">March</option>
              <option value="April">April</option>
              <option value="May">May</option>
              <option value="June">June</option>
              <option value="July">July</option>
              <option value="August">August</option>
              <option value="September">September</option>
              <option value="October">October</option>
              <option value="November">November</option>
              <option value="December">December</option>
            </select>
          </div>
          <div className="w-1/2">
            <label className="block text-gray-700">Academic Year</label>
            <input
              type="text"
              name="academicYear"
              value={newFee.academicYear}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>
        </div>

        {/* Second Row: Amount and Payment Method */}
        <div className="flex gap-4 mb-4">
          <div className="w-1/2">
            <label className="block text-gray-700">Amount</label>
            <input
              type="number"
              name="amount"
              value={newFee.amount}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>
          <div className="w-1/2">
            <label className="block text-gray-700">Payment Method</label>
            <select
              name="paymentMethod"
              value={newFee.paymentMethod}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded"
              required
            >
              <option value="Cash">Cash</option>
              <option value="Credit Card">Credit Card</option>
              <option value="Bank Transfer">Bank Transfer</option>
              <option value="Online">Online</option>
            </select>
          </div>
        </div>

        {/* Third Row: Payment Status and Payment For */}
        <div className="flex gap-4 mb-4">
          <div className="w-1/2">
            <label className="block text-gray-700">Payment Status</label>
            <select
              name="paymentStatus"
              value={newFee.paymentStatus}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded"
              required
            >
              <option value="Paid">Paid</option>
              <option value="Pending">Pending</option>
              <option value="Failed">Failed</option>
            </select>
          </div>
          <div className="w-1/2">
            <label className="block text-gray-700">Payment For</label>
            <select
              name="paymentFor"
              value={newFee.paymentFor}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded"
              required
            >
              <option value="Tuition">Tuition</option>
              <option value="Books">Books</option>
              <option value="Hostel">Hostel</option>
              <option value="Transport">Transport</option>
              <option value="Other">Other</option>
            </select>
          </div>
        </div>

        {/* Fourth Row: Receipt Number and Remarks */}
        <div className="flex gap-4 mb-4">
          <div className="w-1/2">
            <label className="block text-gray-700">Receipt Number</label>
            <input
              type="text"
              name="receiptNumber"
              value={newFee.receiptNumber}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>
          <div className="w-1/2">
            <label className="block text-gray-700">Remarks</label>
            <textarea
              name="remarks"
              value={newFee.remarks}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded"
            />
          </div>
        </div>

        {/* Fifth Row: Due Date, Late Fee, Discount */}
        <div className="flex gap-4 mb-4">
          <div className="w-1/3">
            <label className="block text-gray-700">Due Date</label>
            <input
              type="date"
              name="dueDate"
              value={newFee.dueDate}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div className="w-1/3">
            <label className="block text-gray-700">Late Fee</label>
            <input
              type="number"
              name="lateFee"
              value={newFee.lateFee}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div className="w-1/3">
            <label className="block text-gray-700">Discount</label>
            <input
              type="number"
              name="discount"
              value={newFee.discount}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded"
            />
          </div>
        </div>

        {/* Submit Button */}
        <div className="flex justify-end">
          <button
            type="submit"
            className="bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-semibold py-2 px-6 rounded-full shadow-md hover:shadow-lg transition-all duration-300 ease-in-out"
            disabled={loading}
          >
            {loading ? "Submitting..." : "Add Fee"}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default AddFeeModal;
