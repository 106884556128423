// utils/auth.js
import { jwtDecode } from "jwt-decode"; // Import jwtDecode by name

export const isAuthenticated = () => {
  const token = localStorage.getItem("token");
  if (!token) return false;

  try {
    const decoded = jwtDecode(token); // Use jwtDecode to decode the token
    const currentTime = Date.now() / 1000; // Current time in seconds

    // Check if the token is expired
    if (decoded.exp < currentTime) {
      localStorage.removeItem("token");
      localStorage.removeItem("userName");
      localStorage.removeItem("userType");
      return false;
    }

    return true;
  } catch (error) {
    console.error("Error decoding token", error);
    return false;
  }
};
