import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FeeTable from "./FeeTable";

const base_url = process.env.REACT_APP_BASE_URL_DEVELOPMENT;

const StudentDetailsForTeachers = () => {
  const { id } = useParams();
  const [student, setStudent] = useState(null);
  const [updatedStudent, setUpdatedStudent] = useState({});
  const [feeDetails, setFeeDetails] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [feeLoading, setFeeLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStudentDetails = async () => {
      const token = localStorage.getItem("token");

      try {
        const response = await fetch(`${base_url}/student/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 401) {
          toast.error("Session expired, please login again.");
          return;
        }

        const data = await response.json();
        setStudent(data.data);
        setUpdatedStudent(data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching student details:", error);
        toast.error("Failed to fetch student details.");
        setLoading(false);
      }
    };

    const fetchFeeDetails = async () => {
      const token = localStorage.getItem("token");

      try {
        const response = await fetch(
          `${base_url}/feeCollections/getbystudentid/${id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 401) {
          toast.error("Session expired, please login again.");
          return;
        }

        const feeData = await response.json();
        console.log("Fee Data:", feeData);
        setFeeDetails(feeData || []);
        setFeeLoading(false);
      } catch (error) {
        console.error("Error fetching fee details:", error);
        toast.error("Failed to fetch fee details.");
        setFeeLoading(false);
      }
    };

    fetchStudentDetails();
    fetchFeeDetails();
  }, [id]);

  const handleSave = async () => {
    const token = localStorage.getItem("token");

    const updatedFields = Object.keys(updatedStudent).reduce((acc, key) => {
      if (updatedStudent[key] !== student[key]) {
        acc[key] = updatedStudent[key];
      }
      return acc;
    }, {});

    if (Object.keys(updatedFields).length === 0) {
      toast.info("No changes made.");
      setIsEditMode(false);
      return;
    }

    try {
      const response = await fetch(`${base_url}/student/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedFields),
      });

      if (!response.ok) {
        throw new Error("Failed to update student");
      }

      const data = await response.json();
      setStudent(data.data);
      setIsEditMode(false);
      toast.success("Student updated successfully.");
    } catch (error) {
      console.error("Error updating student:", error);
      toast.error("Failed to update student.");
    }
  };

  const handleDelete = async () => {
    const token = localStorage.getItem("token");
    if (window.confirm("Are you sure you want to delete this student?")) {
      try {
        const response = await fetch(`${base_url}/student/${id}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to delete student");
        }

        toast.success("Student deleted successfully");
        navigate("/student");
      } catch (error) {
        console.error("Error deleting student:", error);
        toast.error("Failed to delete student.");
      }
    }
  };

  const handleCancel = () => {
    setUpdatedStudent(student);
    setIsEditMode(false);
  };

  if (loading || feeLoading) {
    return <div>Loading...</div>;
  }

  if (!student) {
    return <div>No student data available.</div>;
  }

  return (
    <div className="flex flex-col items-center h-screen p-4 ">
      <ToastContainer />
      {!isEditMode && (
        <div className="w-full flex justify-end mb-4">
          <button
            className="bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-semibold py-2 px-6 mb-2 rounded-full shadow-md hover:shadow-lg transition-all duration-300 ease-in-out flex items-center justify-center"
            onClick={() => navigate("/student")}
          >
            Back to Students
          </button>
        </div>
      )}

      <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-6xl mb-6">
        <h1 className="text-2xl font-bold mb-6 text-center text-blue-600">
          Student Details
        </h1>

        {/* Student Details Grid with Image */}
        <div className="grid grid-cols-3 gap-x-8 gap-y-6">
          {/* Profile Image */}
          <div className="flex justify-center items-center">
            <img
              src={student?.personalDetails?.pic} // Dummy image URL
              alt="Student Profile"
              className="w-32 h-32 rounded-full object-cover"
            />
          </div>

          {/* First Column */}
          <div className="flex flex-col gap-y-3">
            <div className="flex items-center">
              <strong className="whitespace-nowrap">Registration No.:</strong>
              <span className="ml-2">{student.registrationNo}</span>
            </div>

            <div className="flex items-center">
              <strong className="whitespace-nowrap">Father's Name:</strong>
              <span className="ml-2">{student?.personalDetails?.father}</span>
            </div>
            <div className="flex items-center">
              <strong className="whitespace-nowrap">Gardian:</strong>
              <span className="ml-2">{student?.personalDetails?.gardian}</span>
            </div>

            <div className="flex items-center">
              <strong className="whitespace-nowrap">Email:</strong>
              <span className="ml-2">{student?.email}</span>
            </div>

            <div className="flex items-center">
              <strong className="whitespace-nowrap">Class:</strong>
              <span className="ml-2">{student.class}</span>
            </div>
            <div className="flex items-center">
              <strong className="whitespace-nowrap">Gender</strong>
              <span className="ml-2">{student?.personalDetails?.gender}</span>
            </div>
            <div className="flex items-center">
              <strong className="whitespace-nowrap">
                Student Contact No.:
              </strong>
              <span className="ml-2">
                {student?.personalDetails?.studentPhoneNumber}
              </span>
            </div>
          </div>

          {/* Second Column */}
          <div className="flex flex-col gap-y-3">
            <div className="flex items-center">
              <strong className="whitespace-nowrap">Name:</strong>
              <span className="ml-2">
                {student.firstName} {student.lastName}
              </span>
            </div>
            <div className="flex items-center">
              <strong className="whitespace-nowrap">Mother's Name:</strong>
              <span className="ml-2">{student?.personalDetails?.mother}</span>
            </div>

            <div className="flex items-center">
              <strong className="whitespace-nowrap">Address:</strong>
              <span className="ml-2">{student?.personalDetails?.address}</span>
            </div>

            <div className="flex items-center">
              <strong className="whitespace-nowrap">
                Father/ Gardian Contact No.:
              </strong>
              <span className="ml-2">
                {student?.personalDetails?.fatherGardianPhoneNumber}
              </span>
            </div>
            <div className="flex items-center">
              <strong className="whitespace-nowrap">Blood Group</strong>
              <span className="ml-2">
                {student?.personalDetails?.bloodGroup}
              </span>
            </div>
            {/* 
1. bloodGroup
2. gender
3. pic
*/}
            <div className="flex items-center">
              <strong className="whitespace-nowrap">Mode of Transport:</strong>
              <span className="ml-2">{student?.modeOfTransport}</span>
            </div>

            <div className="flex items-center">
              <strong className="whitespace-nowrap">Enrollment Date:</strong>
              <span className="ml-2">
                {new Date(student.createdAt).toLocaleDateString()}
              </span>
            </div>
          </div>
        </div>
      </div>

      {!isEditMode && <FeeTable feeDetails={feeDetails.data} studentId={id} />}
    </div>
  );
};

export default StudentDetailsForTeachers;
