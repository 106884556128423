import React, { useState, useEffect } from "react";
import { IoBagHandle } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { HiOutlineSearch } from "react-icons/hi";
import { FaExchangeAlt, FaMoneyBill, FaUserPlus } from "react-icons/fa";

const base_url = process.env.REACT_APP_BASE_URL_DEVELOPMENT;

export const Notification = () => {
  const [Notifications, setNotifications] = useState([]);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");

    fetch(`${base_url}/student/notification`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          navigate("/customer-login");
          return;
        }
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setNotifications(data.data);
        console.log("Fetched Notifications: ", data);
      })
      .catch((error) => {
        console.error("Error fetching Notifications:", error);
        // toast.error("Failed to fetch Notifications.");
      });
  }, [navigate]);

  const handleRowClick = (Notification) => {
    setSelectedNotification(Notification);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedNotification(null);
  };

  return (
    <div className="flex flex-col gap-4 w-full">
      <ToastContainer />
      {/* Box Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <BoxWrapper>
          <div className="rounded-full h-12 w-12 flex items-center justify-center bg-sky-500">
            <IoBagHandle className="text-2xl text-white" />
          </div>
          <div className="pl-4">
            <span className="text-sm text-gray-500 font-light">
              Total Notifications
            </span>
            <div className="flex items-center">
              <strong className="text-xl text-gray-700 font-semibold">
                {Notifications.length}
              </strong>
            </div>
          </div>
        </BoxWrapper>
        <BoxWrapper>
          <div className="rounded-full h-12 w-12 flex items-center justify-center bg-yellow-500">
            <FaMoneyBill className="text-2xl text-white" />
          </div>
          <div className="pl-4">
            <span className="text-sm text-gray-500 font-light">
              Read Notification
            </span>
            <div className="flex items-center">
              <strong className="text-xl text-gray-700 font-semibold">
                {
                  Notifications.filter(
                    (Notification) =>
                      Notification.readBy && Notification.readBy.length > 0
                  ).length
                }
              </strong>
            </div>
          </div>
        </BoxWrapper>

        <BoxWrapper>
          <div className="rounded-full h-12 w-12 flex items-center justify-center bg-orange-500">
            <FaUserPlus className="text-2xl text-white" />
          </div>
          <div className="pl-4">
            <span className="text-sm text-gray-500 font-light">
              Unread Notification
            </span>
            <div className="flex items-center">
              <strong className="text-xl text-gray-700 font-semibold">
                {
                  Notifications.filter(
                    (Notification) =>
                      !Notification.readBy || Notification.readBy.length === 0
                  ).length
                }
              </strong>
            </div>
          </div>
        </BoxWrapper>
        <BoxWrapper>
          <div className="rounded-full h-12 w-12 flex items-center justify-center bg-blue-500">
            <FaExchangeAlt className="text-2xl text-white" />
          </div>
          <div className="pl-4">
            <span className="text-sm text-gray-500 font-light">
              Important Notification
            </span>
            <div className="flex items-center">
              <strong className="text-xl text-gray-700 font-semibold">
                {
                  Notifications.filter((Notification) => Notification.isUrgent)
                    .length
                }
              </strong>
            </div>
          </div>
        </BoxWrapper>
      </div>

      {/* Table Section */}
      <div className="bg-white px-4 pt-3 pb-4 rounded-sm border border-gray-200 flex-1">
        <div className="flex flex-col md:flex-row justify-between items-center mb-2">
          <strong className="text-gray-700 font-medium mb-2 md:mb-0">
            All Notifications
          </strong>
          <div className="relative w-full md:w-auto">
            <HiOutlineSearch
              fontSize={20}
              className="text-gray-400 absolute top-1/2 -translate-y-1/2 left-3"
            />
            <input
              type="text"
              placeholder="Search..."
              className="text-sm focus:outline-none active:outline-none h-10 w-full md:w-[20rem] border border-gray-300 rounded-sm pl-11 pr-4"
            />
          </div>
        </div>
        {/* Table wrapper with fixed height and overflow */}
        <div
          className="overflow-x-auto"
          style={{ maxHeight: "500px", overflowY: "auto" }}
        >
          <table className="min-w-full bg-white border border-gray-300">
            <thead>
              <tr className="bg-gray-100 text-left text-gray-600 uppercase">
                <th className="py-2 px-4 border">Title</th>
                <th className="py-2 px-4 border">Date</th>
                <th className="py-2 px-4 border">Description</th>
              </tr>
            </thead>
            <tbody>
              {Notifications && Notifications.length > 0 ? (
                Notifications.map((Notification) => (
                  <tr
                    key={Notification._id}
                    onClick={() => handleRowClick(Notification)}
                    className="cursor-pointer hover:bg-gray-100"
                  >
                    <td className="py-2 px-4 border">{Notification.title}</td>
                    <td className="py-2 px-4 border">
                      {new Date(Notification.createdAt).toLocaleDateString()}
                    </td>
                    <td className="py-2 px-4 border">{Notification.message}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3" className="text-center py-4">
                    No Notifications found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Modal Section */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg">
            <h2 className="text-xl font-semibold mb-4">Notification Details</h2>
            <div className="mb-4">
              <strong className="text-gray-700">Title:</strong>
              <p>{selectedNotification.title}</p>
            </div>
            <div className="mb-4">
              <strong className="text-gray-700">Date:</strong>
              <p>
                {new Date(selectedNotification.createdAt).toLocaleDateString()}
              </p>
            </div>
            <div className="mb-4">
              <strong className="text-gray-700">Message:</strong>
              <p>{selectedNotification.message}</p>
            </div>
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              onClick={handleCloseModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

function BoxWrapper({ children }) {
  return (
    <div className="bg-white rounded-sm p-4 flex-1 border border-gray-200 flex items-center">
      {children}
    </div>
  );
}

export default Notification;
