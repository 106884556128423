import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";

export const ComplaintDetailsModal = ({ isOpen, onClose, complaint }) => {
  // Check if the complaint object is valid before rendering details
  if (!complaint) {
    return null; // Return nothing if no complaint is passed
  }

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" component="h2">
          Complaint Details
        </Typography>
        <Typography sx={{ mt: 2 }}>
          <strong>Subject:</strong> {complaint.subject || "N/A"}
        </Typography>
        <Typography sx={{ mt: 1 }}>
          <strong>Description:</strong> {complaint.description || "N/A"}
        </Typography>
        <Typography sx={{ mt: 1 }}>
          <strong>Room Number:</strong> {complaint.roomNumber || "N/A"}
        </Typography>
        <Typography sx={{ mt: 1 }}>
          <strong>Status:</strong> {complaint.status || "N/A"}
        </Typography>
        <Typography sx={{ mt: 1 }}>
          <strong>Date:</strong>{" "}
          {complaint.createdAt
            ? new Date(complaint.createdAt).toLocaleString()
            : "N/A"}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={onClose}
          sx={{ mt: 3 }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};
