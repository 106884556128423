import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const base_url = process.env.REACT_APP_BASE_URL_DEVELOPMENT;
const dummyImage = "https://via.placeholder.com/150"; // Dummy image URL

const Profile = () => {
  const { id } = useParams(); // Get user ID from URL params
  const [user, setUser] = useState(null); // State to hold user data
  const [updatedUser, setUpdatedUser] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [profileImage, setProfileImage] = useState(dummyImage); // For the profile image
  const navigate = useNavigate();
  const fileInputRef = React.useRef(null); // Ref for file input

  useEffect(() => {
    // Function to fetch user profile details
    const fetchProfileDetails = async () => {
      const token = localStorage.getItem("token");

      try {
        // Fetch user data from the API
        const response = await fetch(
          `${base_url}/student/66cc2d7d3ec4d3786e78f2df`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 401) {
          toast.error("Session expired, please login again.");
          navigate("/login");
          return;
        }

        const data = await response.json();
        if (response.ok) {
          setUser(data.data);
          setUpdatedUser(data.data); // Set initial values for editing
          setProfileImage(data.data.profileImage || dummyImage); // Set initial profile image
        } else {
          toast.error(data.message || "Failed to fetch profile details.");
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching profile details:", error);
        toast.error("Failed to fetch profile details.");
        setLoading(false);
      }
    };

    fetchProfileDetails();
  }, [navigate, id]);

  // Handle image upload
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result); // Set the image URL to the local preview
      };
      reader.readAsDataURL(file); // Convert the file to a base64-encoded URL for preview
    }
  };

  const handleSave = async () => {
    const token = localStorage.getItem("token");

    // Collect only the fields that have changed
    const updatedFields = Object.keys(updatedUser).reduce((acc, key) => {
      if (updatedUser[key] !== user[key]) {
        acc[key] = updatedUser[key];
      }
      return acc;
    }, {});

    if (Object.keys(updatedFields).length === 0) {
      toast.info("No changes made.");
      setIsEditMode(false);
      return;
    }

    try {
      const response = await fetch(`${base_url}/student/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedFields),
      });

      if (!response.ok) {
        throw new Error("Failed to update profile");
      }

      const data = await response.json();
      setUser(data.data);
      setUpdatedUser(data.data); // Reset updatedUser to reflect saved changes
      setIsEditMode(false);
      toast.success("Profile updated successfully.");
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Failed to update profile.");
    }
  };

  const handleCancel = () => {
    setUpdatedUser(user); // Revert to original state
    setIsEditMode(false);
  };

  const handlePencilClick = () => {
    fileInputRef.current.click(); // Trigger file input click
  };

  if (loading) {
    return <div className="text-center text-gray-600">Loading...</div>;
  }

  if (!user) {
    return (
      <div className="text-center text-gray-600">
        No profile data available.
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center h-screen p-4 bg-gray-100">
      <ToastContainer />

      <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-3xl mb-6 border border-gray-300">
        <h1 className="text-3xl font-semibold mb-6 text-center text-blue-700">
          Profile
        </h1>

        {/* Profile Image Upload with Pencil Icon */}
        <div className="flex flex-col items-center mb-6">
          <img
            src={profileImage}
            alt="Profile"
            className="rounded-full w-32 h-32 border border-gray-300 object-cover mb-4"
          />
          <button
            onClick={handlePencilClick}
            className="border border-gray-300 p-2 rounded-lg bg-white hover:bg-gray-200 focus:outline-none"
            title="Edit Profile Image"
          >
            <i className="fas fa-pencil-alt text-gray-600"></i>{" "}
            {/* Pencil Icon */}
          </button>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            ref={fileInputRef}
            className="hidden" // Hide the input
          />
        </div>

        {/* Profile Details Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-4">
          <div className="flex items-center mb-4">
            <strong className="w-32 text-gray-800">Name:</strong>
            {isEditMode ? (
              <input
                type="text"
                className="ml-4 border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
                value={updatedUser.name}
                onChange={(e) =>
                  setUpdatedUser({ ...updatedUser, name: e.target.value })
                }
              />
            ) : (
              <span className="ml-4 text-gray-600">{user.name}</span>
            )}
          </div>

          <div className="flex items-center mb-4">
            <strong className="w-32 text-gray-800">Email:</strong>
            {isEditMode ? (
              <input
                type="email"
                className="ml-4 border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
                value={updatedUser.email}
                onChange={(e) =>
                  setUpdatedUser({ ...updatedUser, email: e.target.value })
                }
              />
            ) : (
              <span className="ml-4 text-gray-600">{user.email}</span>
            )}
          </div>

          <div className="flex items-center mb-4">
            <strong className="w-32 text-gray-800">Phone Number:</strong>
            {isEditMode ? (
              <input
                type="text"
                className="ml-4 border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
                value={updatedUser.phoneNumber}
                onChange={(e) =>
                  setUpdatedUser({
                    ...updatedUser,
                    phoneNumber: e.target.value,
                  })
                }
              />
            ) : (
              <span className="ml-4 text-gray-600">{user.phoneNumber}</span>
            )}
          </div>

          <div className="flex items-center mb-4">
            <strong className="w-32 text-gray-800">Address:</strong>
            {isEditMode ? (
              <input
                type="text"
                className="ml-4 border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
                value={updatedUser.address}
                onChange={(e) =>
                  setUpdatedUser({ ...updatedUser, address: e.target.value })
                }
              />
            ) : (
              <span className="ml-4 text-gray-600">{user.address}</span>
            )}
          </div>

          <div className="flex items-center mb-4">
            <strong className="w-32 text-gray-800">Class Number:</strong>
            <span className="ml-4 text-gray-600">{user.roomNumber}</span>
          </div>

          <div className="flex items-center mb-4">
            <strong className="w-32 text-gray-800">Registration Date:</strong>
            <span className="ml-4 text-gray-600">
              {new Date(user.createdAt).toLocaleString()}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
